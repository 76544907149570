
const ValidateUser = ({children}) => {
    const referrer = document.referrer;// window.location.href;
    console.log("current url ",referrer);

    if (localStorage.getItem("isLoggedIn")) {
        return children; 
      }
      else {
        if (referrer) {
            window.location.href = referrer;   
        } else {
            window.location.href = '/';
        }
        return null;
    }
}

export default ValidateUser

import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./Userprovider";

const store = configureStore({
    reducer:{
        usr:userSlice.reducer
    },
})

export default store;



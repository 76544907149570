import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getData } from '../apis/service';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // bootstrap 5

function Order() {
    const [allOrder, setAllOrder] = useState([]);
    const [carts, setCarts] = useState({});
    const [shippingAddress, setShippingAddress] = useState({});
    const [cartTotal, setCartTotal] = useState();
    const [orderDate, setOrderDate] = useState('');
    const [orderID, setOrderId] = useState('');    
    //const [userOrderDetails, setUserOrderDetails] = useState({});
    const [orderStatus, setOrderStatus] = useState('');

    useEffect(()=>{
        getOrder()
    },[])

    const getOrder = async () => {
        try {
          var res = await getData('get-user-order'); 
          // console.log("got output ", res?.data); 
           if(res?.data?.error===false)
           {
            setAllOrder(res?.data?.data)
           }
        } catch (error) {   
          console.error('Server Error:', error.message);
        }
      }
    
  const orderDetails = async (id, order_id)=>{
    console.log(id, order_id)
    try{
      setOrderId(order_id)
      setCarts({})
        setShippingAddress({});
        setCartTotal('')
        setOrderDate('')
        setOrderStatus('');  
        
      let pth = 'get-user-order/'+id;
      var res = await getData(pth) 
        console.log("order detail", res.data)
      //   console.log("data", res.payload.data.created_at)
    //   console.log("cart ", res.payload.data.cart_details)
      if(res?.data?.error === false)
      {
       // console.log("its ok");
       const modal = new bootstrap.Modal(document.querySelector('.showModal'));
        modal.show();

        setCarts(res?.data?.data?.order)
        setShippingAddress(res?.data?.data?.shippingAddress);
        setCartTotal(res?.data?.data?.total_amount)
        setOrderDate(res?.data?.data?.created_at)
        setOrderStatus(res?.data?.data?.status);
      }
      
    }   
      catch(error)
      {
        console.log(error)
      }
  }

  
    return (
        <>
    <div className="myaccount-content">
                      <h3>Orders</h3>
                      <div className="myaccount-table table-responsive text-center">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                                <th>#</th>
                              <th>Order</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Total</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {allOrder?.length > 0 && allOrder?.map((or, index) => (
                            <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{or?.order_id}</td>
                                <td>
                                {
                                    (() => {
                                        const date = new Date(or?.created_at);
                                        const day = String(date.getDate()).padStart(2, '0');
                                        const month = date.toLocaleString('en-US', { month: 'short' });
                                        const year = date.getFullYear();
                                        const time = date.toLocaleString('en-US', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true,
                                        });
                              
                                        const timeParts = time.split(' ');
                                        const timeFormatted = timeParts[0]; 
                                        const period = timeParts[1];  
                              
                                        return `${day}-${month}-${year} ${timeFormatted} ${period}`;
                                      })()
                                }
                                </td>
                                <td>{or?.status}</td>
                                <td>₹{or?.total}/-</td>
                                <td>
                                <Link onClick={()=>orderDetails(or?.id, or?.order_id)} className="btn-add-to-cart">View</Link>                                       
                                {/* <Link onClick={()=>orderDetails(or?.id, or?.order_id)} data-toggle="modal" data-target=".bd-example-modal-lg" className="btn-add-to-cart">View</Link> */}
                                    </td>
                            </tr>
                            ))}

                          </tbody>
                        </table>
                      </div>
                    </div>  

  <div className="modal fade bd-example-modal-lg showModal" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg">
    <div className="modal-content">
      <div className='container'>
        <div className='row'>
          <div className='col-md-8' style={{margin:'10px'}}>
            <h4>Shipping Address : </h4>
            <span>{shippingAddress?.first_name} {shippingAddress?.last_name}</span><br />
            <span>{shippingAddress?.address} {shippingAddress?.landmark}</span><br />
            <span>{shippingAddress?.city} - {shippingAddress?.pincode}</span><br />
            <span>Date - {(() => {
                                        const date = new Date(orderDate);
                                        const day = String(date.getDate()).padStart(2, '0');
                                        const month = date.toLocaleString('en-US', { month: 'short' });
                                        const year = date.getFullYear();
                                        const time = date.toLocaleString('en-US', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true,
                                        });
                              
                                        const timeParts = time.split(' ');
                                        const timeFormatted = timeParts[0]; 
                                        const period = timeParts[1];  
                              
                                        return `${day}-${month}-${year} ${timeFormatted} ${period}`;
                                      })()}</span>                      
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Product Name</th>
              <th scope="col">Image</th>
              <th scope="col">Price</th>
              <th scope="col">Qty.</th>
              <th scope="col">Total</th>       
            </tr>
          </thead>
          <tbody>
            {
              carts?.length > 0 && (
                <>
                  {carts?.map((cr, ind) => (
                    <tr key={ind}>
                      <th scope="row">{ind + 1}</th>
                      <td>{cr?.name}</td>
                      <td><img src={cr?.image} alt='img' width="50" height="50" /></td>
                      <td>{cr?.price !== '' ? '₹'+cr?.price+'/-' : ''}</td>
                      <td>{cr?.qty}</td>
                      <td>{cr?.total !== '' ? '₹'+cr?.total+'/-' : ''}</td>
                    </tr>
                  ))}
                  <tr>
                    <th colSpan="4"></th><th>Total</th><th>{cartTotal ? '₹'+cartTotal+'/-' : ''}</th>
                  </tr>
                </>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


     </>                       
  )
}

export default Order
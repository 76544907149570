function validateEmail(email)
{
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    return reg.test(email);
}

export const registerValidate = (props)=>{
    const validate ={
        valid:true,
        msg:'',
        nm:''
    };
    let {rname, remail, rpassword, rrepassword} = props;
    
    //console.log(name, props)
    if(rname==='')
    {
        validate.valid=false;
        validate.msg='Please enter name.';
        validate.nm='rname';
    }
    else if(remail==='')
    {
        validate.valid=false;
        validate.msg='Please enter email.';
        validate.nm='remail';
    }
    else if (!validateEmail(remail)) {
        validate.nm = "remail"
        validate.msg = "Please enter valid e-mail address.";
        validate.valid = false;
      }
    else if(rpassword==='')
      {
          validate.valid=false;
          validate.msg='Please enter password.';
          validate.nm='rpassword';
      }
      else if(rrepassword==='')
      {
          validate.valid=false;
          validate.msg='Please re-enter password';
          validate.nm='rrepassword';
      }   
    else if(rpassword!==rrepassword)
      {
          validate.valid=false;
          validate.msg='Password does not match';
          validate.nm='rrepassword';
      }  

    return {validate};    

    }

    export const forgotPasswordValidate = (props)=>{
        const validate ={
            valid:true,
            msg:'',
            nm:''
        };
        let {femail} = props;
        
        //console.log(props)
        if(femail==='')
            {
                validate.valid=false;
                validate.msg='Please enter email.';
                validate.nm='femail';
            }
            else if (!validateEmail(femail)) {
                validate.nm = "femail"
                validate.msg = "Please enter valid e-mail address.";
                validate.valid = false;
              }
        return {validate};    
        }    

        export const changePasswordValidate = (props)=>{
            const validate ={
                valid:true,
                msg:'',
                nm:''
            };
            let {password, re_password} = props;
            
            //console.log(props)
            if(password==='')
                {
                    validate.valid=false;
                    validate.msg='Please enter password.';
                    validate.nm='password';
                }
                else if (password.length <= 6) {
                    validate.nm = "password"
                    validate.msg = "Password should be greater than 6 characters.";
                    validate.valid = false;
                  }
                else if (password.length >= 50) {
                    validate.nm = "password"
                    validate.msg = "Password should not greater than 50 characters.";
                    validate.valid = false;
                  }
                  else if (password !== re_password) {
                    validate.nm = "re_password"
                    validate.msg = "Password does not match.";
                    validate.valid = false;
                  }  
            return {validate};    
            }    
            
            export const loginValidate = (props)=>{
                const validate ={
                    valid:true,
                    msg:'',
                    nm:''
                };
                let {email, password} = props;
                
                if(email==='')
                {
                    validate.valid=false;
                    validate.msg='Please enter email.';
                    validate.nm='email';
                }
                else if (!validateEmail(email)) {
                    validate.nm = "email"
                    validate.msg = "Please enter valid e-mail address.";
                    validate.valid = false;
                  }
                else if(password==='')
                  {
                      validate.valid=false;
                      validate.msg='Please enter password.';
                      validate.nm='password';
                  }
                  
                return {validate};    
            }

    export const otpValidate = (props)=>{
        const validate ={
            valid:true,
            msg:'',
            nm:''
        };
        let {otp, email} = props;
        
        //console.log(name, props)
        if(otp==='')
        {
            validate.valid=false;
            validate.msg='Please enter otp.';
            validate.nm='otp';
        }
        else if(otp.length !==4)
        {
            validate.valid=false;
            validate.msg='Please enter 4 digit otp.';
            validate.nm='otp';
        }
        else if(isNaN(otp))
        {
            validate.valid=false;
            validate.msg='Please enter 4 digit numeric otp.';
            validate.nm='otp';
        }    
        else if(email==='')
        {
            validate.valid=false;
            validate.msg='OTP does not exists. Please sign up again.';
            validate.nm='otp';
        }
        return {validate};    
        }    


        export const shippingAddressValidate = (props)=>{
            const validate ={
                valid:true,
                msg:'',
                nm:''
            };
            let {fname, lname, phone, address, pincode, landmark, city} = props;
            
            if(fname==='')
            {
                validate.valid=false;
                validate.msg='Please enter first name.';
                validate.nm='fname';
            }
            else if(lname==='')
              {
                  validate.valid=false;
                  validate.msg='Please enter last name.';
                  validate.nm='lname';
              }
              else if(phone==='')
              {
                  validate.valid=false;
                  validate.msg='Please enter phone number.';
                  validate.nm='phone';
              }
              else if(phone.length !== 10 || !/^\d{10}$/.test(phone))
              {
                  validate.valid=false;
                  validate.msg='Please enter a valid 10-digit phone number.';
                  validate.nm='phone';
              }
              else if(address==='')
              {
                  validate.valid=false;
                  validate.msg='Please enter address.';
                  validate.nm='address';
              }
              else if(pincode==='')
              {
                  validate.valid=false;
                  validate.msg='Please enter pincode.';
                  validate.nm='pincode';
              }
              else if(pincode.length!==6)
                {
                    validate.valid=false;
                    validate.msg='Please enter 6 digit pincode.';
                    validate.nm='pincode';
                }
                else if(isNaN(pincode))
                    {
                        validate.valid=false;
                        validate.msg='Please enter 6 digit pincode.';
                        validate.nm='pincode';
                    }  
              else if(landmark==='')
              {
                  validate.valid=false;
                  validate.msg='Please enter landmark.';
                  validate.nm='landmark';
              }
              else if(city==='')
              {
                  validate.valid=false;
                  validate.msg='Please enter city.';
                  validate.nm='city';
              }        

            return {validate};    
        }        

        export const contactValidate = (props)=>{
            const validate ={
                valid:true,
                msg:'',
                nm:''
            };
            let {first_name, last_name, email, subject, message} = props;
            
            if(first_name==='')
                {
                    validate.valid=false;
                    validate.msg='Please enter first name.';
                    validate.nm='first_name';
                }
            else if(last_name==='')
            {
                validate.valid=false;
                validate.msg='Please enter last name.';
                validate.nm='last_name';
            }    
            else if(email==='')
            {
                validate.valid=false;
                validate.msg='Please enter email.';
                validate.nm='email';
            }
            else if (!validateEmail(email)) {
                validate.nm = "email"
                validate.msg = "Please enter valid e-mail address.";
                validate.valid = false;
              }
            else if(subject==='')
              {
                  validate.valid=false;
                  validate.msg='Please enter subject.';
                  validate.nm='subject';
              }
              else if(message==='')
                {
                    validate.valid=false;
                    validate.msg='Please enter message.';
                    validate.nm='message';
                }  
              
            return {validate};    
        }

        export const profileValidate = (props)=>{
            const validate ={
                valid:true,
                msg:'',
                nm:''
            };
            let {first_name, last_name, old_password, password, re_password} = props;
            
            if(first_name==='')
                {
                    validate.valid=false;
                    validate.msg='Please enter first name.';
                    validate.nm='first_name';
                }
            else if(last_name==='')
            {
                validate.valid=false;
                validate.msg='Please enter last name.';
                validate.nm='last_name';
            }    
            else if(password || re_password)
              {
                  if(old_password === '')
                  {
                    validate.valid=false;
                    validate.msg='Please enter old password.';
                    validate.nm='old_password';
                  }
                  else if(password === '')
                    {
                      validate.valid=false;
                      validate.msg='Please enter password.';
                      validate.nm='password';
                    }
                  else if(re_password === '')
                  {
                    validate.valid=false;
                    validate.msg='Please re-enter password.';
                    validate.nm='re_password';
                  }
                  else if(password !== re_password)
                    {
                      validate.valid=false;
                      validate.msg='Password does not match.';
                      validate.nm='re_password';
                    }
              }
             
              
            return {validate};    
        }        



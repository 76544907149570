import {createAsyncThunk} from '@reduxjs/toolkit'
//import api from './api'
import papi from './papi';
import api from './api';


export const login = createAsyncThunk(
    'login',
    async(data, {rejectWithValue})=>{
        try{
            console.log('dt ', data.pth, data.data)
            let res = await papi.post(data.pth, data.data);
            console.log(res.data)
            return res.data;
        }
        catch(error)
        {
            console.log(error)
            return rejectWithValue(error.response.data);
        }
    }
)

export const register = createAsyncThunk(
    'register',
    async(data, {rejectWithValue})=>{
        try{
            console.log('dt ', data.pth, data.data)
            let res = await papi.post(data.pth, data.data);
            console.log(res.data)
            return res.data;
        }
        catch(error)
        {
            console.log(error)
            return rejectWithValue(error.response.data);
        }
    }
)

export const userOTP = createAsyncThunk(
    'userOTP',
    async(data, {rejectWithValue})=>{
        try{
            console.log('dt ', data.pth, data.data)
            let res = await papi.post(data.pth, data.data);
            console.log(res.data)
            return res.data;
        }
        catch(error)
        {
            console.log(error)
            return rejectWithValue(error.response.data);
        }
    }
)

export const userNormalRoute = createAsyncThunk(
    'userNormalRoute',
    async(fd, {rejectWithValue})=>{
        try{
            let res = await papi[fd.method](fd.pth, fd.data); 
            return res.data;
        }
        catch(error){
            console.log(error); 
            return rejectWithValue(error.response.data);
        }
    }
)  

export const getData = createAsyncThunk(
    'getData',
    async(pth, {rejectWithValue})=>{
        try{
           // console.log('url is', papi, pth);
           const headers = {
            //'Content-Type': 'application/json',   // If you're sending JSON
            'Accept': 'application/json',         // Expect JSON response
            // 'Authorization': `Bearer ${token}`,
        };
            let res = await papi.get(pth, {headers});
            //console.log(res.data)
            return res.data;
        }
        catch(error)
        {
            console.log(error, error.response.data)
            return rejectWithValue(error.response.data);
        }
    }
)

export const userData = createAsyncThunk(
    'userData', 
    async(dt, {rejectWithValue})=>{
        try{
            const headers = {
                'Accept': 'application/json', 
            };
            let res = await api[dt.method](dt.pth, dt.data, {headers});
            return res.data;
        }
        catch(error){
            console.log(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getUserData = createAsyncThunk(
    'getUserData', 
    async(pth, {rejectWithValue})=>{
        try{
            const headers = {
                'Accept': 'application/json', 
            };
            let res = await api.get(pth, {headers});
            return res.data;
        }
        catch(error){
            console.log(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const userCart = createAsyncThunk(
    'userCart', 
    async(dt, {rejectWithValue})=>{
        try{
            const headers = {
                'Accept': 'application/json', 
            };
            let res = await api.post(dt.pth, dt.data, {headers});
            return res.data;
        }
        catch(error){
            console.log(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const updateCartDtl = createAsyncThunk(
    'updateCartDtl', 
    async(dt, {rejectWithValue})=>{
        try{
            const headers = {
                'Accept': 'application/json', 
            };
            let res = await api.post(dt.pth, dt.data, {headers});
            return res.data;
        }
        catch(error){
            console.log(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getCart = createAsyncThunk(
    'getCart', 
    async(pth, {rejectWithValue})=>{
        try{
            const headers = {
                'Accept': 'application/json', 
            };
            let res = await api.get(pth, {headers});
            return res.data;
        }
        catch(error){
            console.log(error);
            return rejectWithValue(error.response.data);
        }
    }
)


/// wishlist

export const userWishlist = createAsyncThunk(
    'userWishlist', 
    async(dt, {rejectWithValue})=>{
        try{
            const headers = {
                'Accept': 'application/json', 
            };
            let res = await api.post(dt.pth, dt.data, {headers});
            return res.data;
        }
        catch(error){
            console.log(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getWishlist = createAsyncThunk(
    'getWishlist', 
    async(pth, {rejectWithValue})=>{
        try{
            const headers = {
                'Accept': 'application/json', 
            };
            let res = await api.get(pth, {headers});
            return res.data;
        }
        catch(error){
            console.log(error);
            return rejectWithValue(error.response.data);
        }
    }
)








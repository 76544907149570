import React from 'react'

const MyButton = ({
    type,
    name,
    cls,
    value,
    err,
    errMsg,
    loading,
    ...props
}) => {
  return (
    <>
     <button type={type} name={name} className={cls} disabled={loading} {...props} >{loading ? 'Please Wait...' : value} </button> 
     {props.errMsg !== '' ?  <p style={{textAlign:'center', color:err ? '#D82E2E' : 'green' }} > <br />{errMsg}</p> : '' }
    </>
  )
}

export default MyButton

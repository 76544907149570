import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getData, userCart } from '../../actions/Useraction';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';


function QuickView({prd}) {
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(()=>{
    //console.log("props", prd?.id)
      fetchProductImages(prd?.id);
  },[prd?.id, dispatch])

  const fetchProductImages = async (productId) => {
    try {
      const data = await dispatch(getData(`get-product-images/${prd?.id}`));
      console.log("rec ", data?.payload?.output);
      setImages(data.payload.output)
      setImageLoaded(true);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const renderThumbs = () => {
    return images?.map((im) => (
      <img src={im?.imag} key={im?.id} alt="Thumbnail" className="img-fluid thumb-img" />
    ));
  };

  const handleAddToCart = async(id)=>{
    //console.log("Item added to cart");
  
    try{
      var resp = {
        pth:'add-to-cart',
        data:{
          prd:id
        }
      }
      await dispatch(userCart(resp))
      
    }
    catch (error) {
      console.error('Error fetching products:', error, error);
    }
  }


  return (
    <>
{/*== Product Quick View Modal Area Wrap ==*/}
<div className="modal fade" id="quickView" tabIndex={-1} role="dialog">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true"><img src="/assets/img/cancel.png" alt="Close" className="img-fluid" /></span>
      </button>
      <div className="modal-body">
        <div className="quick-view-content single-product-page-content">
          <div className="row">
            {/* Product Thumbnail Start */}
            <div className="col-lg-5 col-md-6">
              <div className="product-thumbnail-wrap">
                <div className="">
                <Carousel autoPlay="true" infiniteLoop="true" emulateTouch="true" renderThumbs={renderThumbs} showThumbs={true} dynamicHeight={true} key={imageLoaded}>
                {images?.length > 0 ? (
          images?.map((im, index) => (
            <div className="single-thumb-item" key={index}>
              <a href="single-product.html">
                <img className="img-fluid" src={im?.imag} alt="Product" onLoad={() => setImageLoaded(true)} style={{ width:'332px', height:'420px' }} />
              </a>
            </div>  
          ))
        ) : (
          <p>No images available</p>
        )}
        </Carousel>
            
                </div>
              </div>
            </div>
            {/* Product Thumbnail End */}
            {/* Product Details Start */}
            <div className="col-lg-7 col-md-6 mt-5 mt-md-0">
              <div className="product-details">
                <h2><a href={`/shop/product/${prd?.slug}`}>{prd?.name}</a></h2>
                {/* <div className="rating">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star-half" />
                  <i className="fa fa-star-o" />  
                </div> */}
                <span className="price">₹ {prd?.price} 
                {prd?.old_price && prd?.old_price !== 0.0 && (
                                <>
                                  <s style={{ fontSize: '12px' }}> ₹{prd?.old_price}</s>
                                </>
                              )}
                 
                  </span> 
                {
                            prd?.stock > 0 ? <span className="text-success">In Stock</span> : <span className="text-danger">Out of Stock</span>
                          }
                {/* <div className="product-info-stock-sku">
                  <span className="product-stock-status">In Stock</span>
                  <span className="product-sku-status ml-5"><strong>SKU</strong> MH03</span>
                </div> */}
                
                <p className="products-desc" dangerouslySetInnerHTML={{ __html: prd?.short_desc ? prd?.short_desc : prd?.desc }}></p>
                {/* <div className="shopping-option-item">
                  <h4>Color</h4>
                  <ul className="color-option-select d-flex">
                    <li className="color-item black">
                      <div className="color-hvr">
                        <span className="color-fill" />
                        <span className="color-name">Black</span>
                      </div>
                    </li>
                    <li className="color-item green">
                      <div className="color-hvr">
                        <span className="color-fill" />
                        <span className="color-name">green</span>
                      </div>
                    </li>
                    <li className="color-item orange">
                      <div className="color-hvr">
                        <span className="color-fill" />
                        <span className="color-name">Orange</span>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div className="product-quantity d-flex align-items-center">
                  {/* <div className="quantity-field">
                    <label htmlFor="qty">Qty</label>
                    <input type="number" id="qty" min={1} max={100} defaultValue={1} />
                  </div> */}
                  {
                        prd?.stock > 0 ? <Link onClick={()=>handleAddToCart(prd.id)} className="btn btn-add-to-cart">+ Add to Cart</Link> : <Link className="btn btn-add-to-cart disable">+ Add to Cart</Link>
                        }
                  
                </div>
              </div>
            </div>
            {/* Product Details End */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/*== Product Quick View Modal Area End ==*/}

    </>
  )
}

export default QuickView
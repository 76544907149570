import React, { useEffect, useState } from 'react';
import { getData } from '../../actions/Useraction';
import { useDispatch } from 'react-redux';


function Footer() 
{
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  useEffect(()=>{
    fetchCategories()
  }, [dispatch])
  
  const fetchCategories = async () => {
    try {
      const data = await dispatch(getData('get-categories'));
      setCategories(data?.payload?.output)
      
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const styles = {
    footer: {
        // backgroundColor: '#ba9b7e',
        padding: '40px 0px 20px 0px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
       color: '#ffffff',

    },
    footerTop: {
        borderBottom: '1px solid #444',
        marginBottom:'10px',
       
        paddingBottom:'10px',
    },
    about: {
        marginBottom: '20px',
    },
    linkTitle: {
        marginBottom: '15px',
        color: '#fff',
    },
    linkList: {
        listStyle: 'none',
        padding: 0,
    },
    linkItem: {
        marginBottom: '10px',
    },
    link: {
        color: '#ffffff',
        textDecoration: 'none',
    },
    socialIcons: {
        marginTop: '10px',
        display: 'flex',
        gap: '10px',
    },
    bottomtage:{
      textAlign:'center',
    },
    '@media (max-width: 768px)': {
        footer: {
            padding: '20px 0',

            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center bottom',
            color: '#ffffff',
    
        },
        linkTitle: {
            fontSize: '18px',
        },
        about: {
            fontSize: '14px',
        },
        linkItem: {
            marginBottom: '5px',
        },
    },
};

  

  return (
    <>
    <footer id="footer-area">
    {/* Footer Call to Action Start */}
    <div className="footer-callto-action">
      <div className="ruby-container">
        <div className="callto-action-wrapper">
          <div className="row">
            <div className="col-lg-3 col-md-6"> 
              {/* Single Call-to Action Start */}
              <div className="single-callto-action d-flex">
                <figure className="callto-thumb">
                  {/* <img src="/assets/img/air-plane.png" alt="WorldWide Shipping" /> */}
                  <i className="fa fa-home" aria-hidden="true" style={{fontSize:'40px', color:'#946C56'}}></i>
                </figure>
                <div className="callto-info">
                 <p>
                 At Vivan Homes, customer always comes first. It inspires everything that we do.
                 </p>
                  {/* <h2>Free Shipping Worldwide</h2>
                  <p>On order over $150 - 7 days a week</p> */}
                </div>
              </div>
              {/* Single Call-to Action End */}
            </div>
            <div className="col-lg-3 col-md-6">
              {/* Single Call-to Action Start */}
              <div className="single-callto-action d-flex">
                <figure className="callto-thumb">
                  {/* <img src="/assets/img/support.png" alt="Support" /> */}
                  <i className="fa fa-headphones" aria-hidden="true" style={{fontSize:'40px', color:'#946C56'}}></i>
                </figure>
                <div className="callto-info">
                 <p>With round-the-clock service, you can shop at your own pace, any time that suits you</p>
                  {/* <h2>24/7 CUSTOMER SERVICE</h2>
                  <p>Call us 24/7 at +91 82877 66833  </p> */}
                </div>
              </div>
              {/* Single Call-to Action End */}
            </div>
            <div className="col-lg-3 col-md-6">
              {/* Single Call-to Action Start */}
              <div className="single-callto-action d-flex">
                <figure className="callto-thumb">
                  {/* <img src="/assets/img/money-back.png" alt="Money Back" /> */}
                  <i className="fa fa-shield" aria-hidden="true" style={{fontSize:'40px', color:'#946C56'}}></i>
                </figure>
                <div className="callto-info">
                  <p>At Vivan Homes, authenticity isn't just a promise; it's our commitment to you.</p>
                  {/* <h2>MONEY BACK Guarantee!</h2>
                  <p>Send within 30 days</p> */}
                </div>
              </div>
              {/* Single Call-to Action End */}
            </div>
            <div className="col-lg-3 col-md-6">
              {/* Single Call-to Action Start */}
              <div className="single-callto-action d-flex">
                <figure className="callto-thumb">
                  {/* <img src="/assets/img/cog.png" alt="Guide" /> */}
                  <i className="fa fa-star" aria-hidden="true" style={{fontSize:'40px', color:'#946C56'}}></i>
                </figure>
                <div className="callto-info">
                  <p>Your feedback is important to us. We enjoy hearing from every one of you.</p>
                  {/* <h2>SHOPPING GUIDE</h2>
                  <p>Quis Eum Iure Reprehenderit</p> */}
                </div>
              </div>
              {/* Single Call-to Action End */}
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Footer Call to Action End */}
    {/* Footer Follow Up Area Start */}
   
    <div style={{ ...styles.footer, backgroundImage: 'url(/assets/img/footerbanner.jpg)' }}>
            <div style={styles.footerTop}>
                <div className="container">
                    <div className="row">
                      

                        <div className="col-12 col-lg-3 mt-4 footer-about">
                        <h4 style={styles.linkTitle}>About Vivan Homes </h4>
                            <p style={styles.about}>
                            We are proud to offer our meticulously crafted towels, each designed to meet the highest standards of quality and authenticity. From the first design to the final product, we oversee every step to ensure that every towel reflects true craftsmanship and exceptional excellence.
                            </p>
                            <div style={styles.socialIcons}>
                                <a href="https://www.facebook.com/profile.php?id=61563942341725"><i className="fa fa-facebook breadcrumb_active"></i></a>
                                <a href="https://www.instagram.com/vivanhomes/"><i className="fa fa-instagram breadcrumb_active"></i></a>
                                <a href="https://wa.me/918287766833"><i className="fa fa-whatsapp breadcrumb_active"></i></a>
                                <a href="https://in.pinterest.com/01i5lixr11r0ha3lisfix8bsali7fx/"><i className="fa fa-pinterest-p breadcrumb_active"></i></a>
                            </div>
                        </div>

                        <div className="col-6 col-lg-3 mt-4 footer-links">
                            <h4 style={styles.linkTitle}>Categories</h4>
                            <ul style={styles.linkList}>
                            {
                                categories?.length > 0 ? categories?.map((ct, index)=>(
                                  <li key={index} style={styles.linkItem}><a href={`/shop/${ct?.slug}`} style={styles.link}>{ct?.name}</a></li>
                                )) : <></>
                              }
                          
                            </ul>
                        </div>

                        <div className="col-6 col-lg-3 mt-4 footer-links">
                            <h4 style={styles.linkTitle}>Informations</h4>
                            <ul style={styles.linkList}>
                            <li style={styles.linkItem}><a href="/about" style={styles.link}>About</a></li>
                                <li style={styles.linkItem}><a href="/privacy-policy" style={styles.link}>Privacy Policy</a></li>
                                <li style={styles.linkItem}><a href="/terms-conditions" style={styles.link}>Terms & Condition</a></li>
                                <li style={styles.linkItem}><a href="/refund-policy" style={styles.link}>Refund Policy</a></li>
                                <li style={styles.linkItem}><a href="/shipping-policy" style={styles.link}>Shipping & Delivery Policy</a></li>
                            </ul>
                        </div>

                        <div className="col-12 col-lg-3 mt-4 footer-contact">
                            <h4 style={styles.linkTitle}>Enquiry</h4>
                            <p style={{ color: 'white' }}><i className="fa fa-phone"></i> <a href="tel:+918287766833" style={styles.link}>+91 82877 66833</a></p>
                            <p style={{ color: 'white' }}><i className="fa fa-envelope"></i> <a href="mailto:mail@vivanhomes.com" style={styles.link}>mail@vivanhomes.com</a></p>
                        </div>
                    </div>
                </div>

               
            </div>
             {/* footer tagname */}
            <div className='row'>
                  <div className="col-12 col-lg-12 mt-4 footer-bottom" style={styles.bottomtage}>
                  <div className="copytext text-white">© 2024 Vivan. All Rights Reserved.</div>
                  </div>
                </div>
        </div>

    {/* Footer Follow Up Area End */}
    {/* Footer Image Gallery Area Start */}
    {/* <div className="footer-image-gallery">
      <div className="ruby-container">
        <div className="image-gallery-wrapper">
          <div className="row">
            <div className="col-lg-12">
              <div className="imgage-gallery-carousel owl-carousel">
                <div className="gallery-item">
                  <a href="#"><img src="/assets/img/gallery-img-1.jpg" alt="Gallery" /></a>
                </div>
                <div className="gallery-item">
                  <a href="#"><img src="/assets/img/gallery-img-2.jpg" alt="Gallery" /></a>
                </div>
                <div className="gallery-item">
                  <a href="#"><img src="/assets/img/gallery-img-3.jpg" alt="Gallery" /></a>
                </div>
                <div className="gallery-item">
                  <a href="#"><img src="/assets/img/gallery-img-4.jpg" alt="Gallery" /></a>
                </div>
                <div className="gallery-item">
                  <a href="#"><img src="/assets/img/gallery-img-3.jpg" alt="Gallery" /></a>
                </div>
                <div className="gallery-item">
                  <a href="#"><img src="/assets/img/gallery-img-2.jpg" alt="Gallery" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    {/* Footer Image Gallery Area End */}
    {/* Copyright Area Start */}
    {/* <div className="copyright-area">
      <div className="ruby-container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="copyright-text">
              <p><a target="_blank" href="https://www.templateshub.net">Templates Hub</a></p>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    {/* Copyright Area End */}
  </footer>
    </>
  )
}

export default Footer
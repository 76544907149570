import React from 'react'
import Layout from '../partials/Layout'
import {useDispatch, useSelector} from 'react-redux'
import { updateCartDtl, userCart } from '../../actions/Useraction';
import { Link } from 'react-router-dom';

function Cart() {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const state = useSelector((state)=>{
    return state.usr;
  })

const removeCart = async(id)=>{
  //console.log("Item added to cart");
  if(localStorage.getItem('isLoggedIn'))
    {
  try{
    var resp = {
      pth:'remove-cart',
      data:{
        cart_id:id
      }
    }
    await dispatch(userCart(resp))
  }
  catch (error) {
    console.error('Error in removing item:', error);
  }
}
}

const updateCart = async(id, qty)=>{
  console.log("cart update", id, qty);
  if(localStorage.getItem('isLoggedIn'))
    {
  try{
    if(qty>0)
    {
    var resp = {
      pth:'update-cart',
      data:{
        cart_id:id,
        qty:qty
      }
    }
    var res = await dispatch(updateCartDtl(resp))
    console.log(res)
  }
  }
  catch (error) {
    console.error('Error in removing item:', error);
  }
}
}


return (
    <>
    <Layout>
<div>
  {/*== Page Title Area Start ==*/}
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>Shopping Cart</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/shop">Shop</a></li>
              <li><a href="/cart" className="active breadcrumb_active">Cart</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
 
 

 
  
  <div id="page-content-wrapper" className="p-9">
    <div className="container">
    {
  state?.loading ? 
  <div className="loader-container">
  <img src="/assets/img/loader.svg" alt="loader" />
</div> 
  :
  
      state?.cart?.length > 0 ?  
      <>
      <div className="row">
        <div className="col-lg-12">
           
          <div className="cart-table table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="pro-thumbnail">Thumbnail</th>
                  <th className="pro-title">Product</th>
                  <th className="pro-price">Price</th>
                  <th className="pro-quantity">Quantity</th>
                  <th className="pro-subtotal">Total</th>
                  <th className="pro-quantity">Stock Status</th>
                  <th className="pro-remove">Remove</th>
                </tr>
              </thead>
              <tbody>
              {
                  state?.cart?.length > 0 ? state?.cart?.map((pr, index)=>(
                <tr key={index}>
                  <td className="pro-thumbnail"><a href={`/shop/${pr?.cart_dtl?.pth}`}><img className="img-fluid" src={pr?.cart_dtl?.image} alt="Product" /></a></td>
                  <td className="pro-title"><a href={`/shop/${pr?.cart_dtl?.pth}`}>{pr?.cart_dtl?.name}</a></td>
                  <td className="pro-price"><span>₹ {pr?.cart_dtl?.price}</span></td>
                  <td className="pro-quantity">
                    <div className="pro-qty"><input type="number" min="1" value={pr?.cart_dtl?.qty} onChange={(e)=>updateCart(pr?.cart_dtl?.id, e.target.value)} /></div>
                  </td>
                  <td className="pro-subtotal"><span>₹ {pr?.cart_dtl?.qty * pr?.cart_dtl?.price}</span></td>
                  <td className="pro-quantity">{pr?.cart_dtl?.stock > 0 ? <span className="text-success">In Stock</span> : <span className="text-danger">Out of Stock</span>}</td>
                  <td className="pro-remove"><Link onClick={()=>removeCart(pr?.cart_dtl?.id)} ><i className="fa fa-trash-o" /></Link></td>
                </tr>
                )) : <tr></tr> }
              </tbody>
            </table>
          </div>
          
          {/* <div className="cart-update-option d-block d-lg-flex">
            <div className="apply-coupon-wrapper">
              <form action="#" method="post" className=" d-block d-md-flex">
                <input type="text" placeholder="Enter Your Coupon Code" />
                <button className="btn-add-to-cart">Apply Coupon</button>
              </form>
            </div>
            <div className="cart-update">
              <a href="#" className="btn-add-to-cart">Update Cart</a>
            </div>
          </div> */}
        </div>
      </div>
    
      
      <div className="row">
        <div className="col-lg-6 ml-auto">
          <div className="cart-calculator-wrapper">
            <h3>Cart Totals</h3>
            <div className="cart-calculate-items">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td className="total-amount">₹ {state?.total_cart_amt}</td>
                    </tr>
                  </tbody></table>
              </div>
            </div>
            <a href="/checkout" className="btn-add-to-cart">Proceed To Checkout</a>
          </div>
        </div>
      </div></> : <div style={{ textAlign: 'center', marginTop:'30px',marginBottom:'50px' }}>
    <img src="/assets/img/empty-cart.webp" width="300" height="300"/>   
    <p>Your cart is empty. <a href="/shop">Click here</a> to continue shopping.</p>
    </div>
    }
    </div>
  </div>
  
</div>

    </Layout>
    </>
  )
}

export default Cart
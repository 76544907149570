import React, { useEffect } from 'react'
import Layout from '../partials/Layout'
import {useDispatch, useSelector} from 'react-redux'
import { getWishlist, userCart, userWishlist } from '../../actions/Useraction';
import { Link } from 'react-router-dom';

function Wishlist() {
  const dispatch = useDispatch();
  const state = useSelector((state)=>{
    return state.usr;
  })

  useEffect(()=>{
    getWishData()
  }, [])

  const getWishData = async ()=>{
    try{
      await dispatch(getWishlist('get-wishlist-details'));
    }
    catch(error)
    {
      console.log(error)
    }
  }

  const removeCart = async(id)=>{
    //console.log("Item added to cart");
    if(localStorage.getItem('isLoggedIn'))
      {
    try{
      var resp = {
        pth:'remove-wishlist',
        data:{
          cart_id:id
        }
      }   
      await dispatch(userWishlist(resp))
    }
    catch (error) {
      console.error('Error in removing item:', error);
    }
  }
  }

  const handleAddToCart = async(id)=>{
    try{
      var resp = {
        pth:'add-to-cart',
        data:{
          prd:id
        }
      }
      await dispatch(userCart(resp))
      
    }
    catch (error) {
      console.error('Error fetching products:', error, error);
    }
  }

  return (
    <>
    <Layout>
<div>
  {/*== Page Title Area Start ==*/}
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>Wishlist</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/shop">Shop</a></li>
              <li><a href="/wishlist" className="active breadcrumb_active">Wishlist</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*== Page Title Area End ==*/}
  {/*== Page Content Wrapper Start ==*/}
  <div id="page-content-wrapper" className="p-9">
    <div className="container">
      {/* Wishlist Page Content Start */}
      <div className="row">
        <div className="col-lg-12">
          {/* Wishlist Table Area */}
          <div className="cart-table table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="pro-thumbnail">Thumbnail</th>
                  <th className="pro-title">Product</th>
                  <th className="pro-price">Price</th>
                  <th className="pro-quantity">Stock Status</th>
                  <th className="pro-subtotal">Add to Cart</th>
                  <th className="pro-remove">Remove</th>
                </tr>
              </thead>
              <tbody>
              {
                  state?.wishlist?.length > 0 ? state?.wishlist?.map((pr, index)=>(
                <tr key={index}>
                  <td className="pro-thumbnail"><a href={`/shop/${pr?.wishlist?.pth}`}><img className="img-fluid" src={pr?.wishlist?.image} alt="Product" /></a></td>
                  <td className="pro-title"><a href={`/shop/${pr?.wishlist?.pth}`}>{pr?.wishlist?.name}</a></td>
                  <td className="pro-price"><span>₹ {pr?.wishlist?.price}</span></td>
                 
                  <td className="pro-quantity">{pr?.wishlist?.stock > 0 ? <span className="text-success">In Stock</span> : <span className="text-danger">Out of Stock</span>}</td>
                  <td className="pro-subtotal"><Link onClick={()=>handleAddToCart(pr?.wishlist?.product)} className="btn-add-to-cart">Add to Cart</Link></td>
                  <td className="pro-remove"><Link onClick={()=>removeCart(pr?.wishlist?.id)} ><i className="fa fa-trash-o" /></Link></td>
                </tr>
                )) : <tr></tr> }
              
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Wishlist Page Content End */}
    </div>
  </div>
  {/*== Page Content Wrapper End ==*/}
</div>

    </Layout>
    </>
  )
}

export default Wishlist
import React, { useEffect, useState } from 'react'
import { profileValidate } from '../validations/validate';
import { getData, postData } from '../apis/service';

const dataInitial = {
  first_name:'',
  last_name:'', 
  contact:'',
  email:'',
  old_password:'',
  password:'',
  re_password:''
};

function Profile() {
  const [fm, setFm] = useState(dataInitial);
  const [err, setErr] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [btnMsg, setBtnMsg] = useState("");
  const [errName, setErrName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    getProfile()
  },[])
  
  const getProfile = async()=>{
    try{
    var res = await getData('get-user-profile');
    if(res?.data?.error===false)
           {
            setFm({
              ...dataInitial,
              ...res?.data?.data,
            });
           }
          }  
    catch(error)
    {
      console.error('Server Error:', error.message);
      return { error: true, message: error.message };
    }       
  }

  const fmSubmit = async (e) => {
    e.preventDefault();
  
    const validationResult = profileValidate(fm);
    if (!validationResult.validate.valid) {
      setErrName(validationResult.validate.nm);
      setErrMsg(validationResult.validate.msg);
      return;
    }
  
    setLoading(true); 
  
    try {
      const res = await postData('get-user-profile', fm);
      console.log("Response data:", res.data, res.data.msg);
        setErr(res?.data?.error);
        setBtnMsg(res?.data?.msg);
        setTimeout(() => {
          setBtnMsg('');
          getProfile();
        }, 3000);
     
    } catch (error) {
      console.error('Submission Error:', error.message);
      setErr(true)
      setBtnMsg('Internal server error. Please try again later.');
      setTimeout(() => {
        setBtnMsg('');
      }, 3000);
    } finally {
      setLoading(false); 
  };
  }
 
  const onFmChange = (e)=>{
    console.log(errName, errMsg)
    setErrName('');
    //setErrMsg('')
    let {name, value} = e.target;
    setFm({...fm, [name]:value})
    
  }   

  return (
    <div className="myaccount-content">
    <h3>Account Details</h3>
    <div className="account-details-form">
      <form onSubmit={fmSubmit}>
        <div className="row">
          <div className="col-lg-6">
          <div className="single-input-item">
              <label htmlFor="first_name" className="required">First Name</label>
              <input type="text" name="first_name" id="first_name" placeholder="First Name" onChange={onFmChange} value={fm?.first_name} />
              {errName === 'first_name' && errMsg && (<p style={{ color: '#D82E2E' }}>{errMsg}</p>)}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single-input-item">
              <label htmlFor="last-name" className="required">Last Name</label>
              <input type="text" name="last_name" id="last_name" placeholder="Last Name" onChange={onFmChange} value={fm?.last_name} />
              {errName === 'last_name' && errMsg && (<p style={{ color: '#D82E2E' }}>{errMsg}</p>)}
            </div>
          </div>
        </div>
        <div className="single-input-item">
          <label htmlFor="display-name" className="required">Contact</label>
          <input type="text" id="contact" name="contact" placeholder="Contact" onChange={onFmChange} value={fm?.contact} />
        </div>
        <div className="single-input-item">
          <label htmlFor="email" className="required">Email Address</label>
          <input type="text" placeholder="Email Address" value={fm?.email} disabled={true} />
        </div>
        <fieldset>
          <legend>Password change</legend>
          <div className="single-input-item">
            <label htmlFor="current-pwd" className="required">Current Password</label>
            <input type="password" name="old_password" id="old_password" placeholder="Current Password" onChange={onFmChange} value={fm?.old_password} />
            {errName === 'old_password' && errMsg && (<p style={{ color: '#D82E2E' }}>{errMsg}</p>)}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="single-input-item">
                <label htmlFor="new-pwd" className="required">New Password</label>
                <input type="password" name="password" id="password" placeholder="New Password" onChange={onFmChange} value={fm?.password} />
                {errName === 'password' && errMsg && (<p style={{ color: '#D82E2E' }}>{errMsg}</p>)}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-input-item">
                <label htmlFor="confirm-pwd" className="required">Confirm Password</label>
                <input type="password" name="re_password" id="re_password" placeholder="Confirm Password" onChange={onFmChange} value={fm?.re_password} />
                {errName === 're_password' && errMsg && (<p style={{ color: '#D82E2E' }}>{errMsg}</p>)}
              </div>
            </div>
          </div>
        </fieldset>
        <div className="single-input-item">
          <button type="submit" name="submit" className="btn-login btn-add-to-cart" disabled={loading} >{loading ? 'Please Wait...' : 'Save Changes'}</button>
          {btnMsg !== '' ?  <p style={{textAlign:'center', color:err ? '#D82E2E' : 'green' }} > <br />{btnMsg}</p> : '' }
        </div>
      </form>
    </div>
  </div>
  )
}

export default Profile
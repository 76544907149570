import React, { useState } from 'react'
import Layout from '../partials/Layout'
import { contactValidate } from './validations/validate';
import MyInput from './my_component/MyInput'
import MyButton from './my_component/MyButton' 
import papi from '../../actions/papi';
import { postpData } from './apis/service';

const contactInitial = {
  first_name:'',
  last_name:'', 
  email:'',
  subject:'',
  message:''
}
    
function Contact() {
  const [fm, setFm] = useState(contactInitial);
  const [err, setErr] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [errName, setErrName] = useState("");
  const [loading, setLoading] = useState(false);
  const [contactMsg, setContactMsg] = useState("");

  const fmSubmit = async (e)=>{
    e.preventDefault();
    console.log(fm)
    let check = contactValidate(fm);
    if (!check.validate.valid) {
      setErrName(check.validate.nm);
      setErrMsg(check.validate.msg);
      return;
    }
      setLoading(true);     
      console.log("its ok", fm)
    try{
  
      let res = await postpData('user-enquiry', fm);
      console.log("Response data:", res.data, res.data.msg);
      setErr(res.data.error)
      setContactMsg(res.data.msg)
            setTimeout(() => {
              setContactMsg('');
              setFm(contactInitial)    
            }, 5000);
     
  }
  catch(error)
  {   
      console.log(error)
      setErr(true)
      setContactMsg('Internal server error. Please try again later.');
      setTimeout(() => {
        setContactMsg('');
      }, 3000);
      
  }
    finally { 
      setLoading(false);
    }
  
  }

 
  const onFmChange = (e)=>{
    console.log(errName, errMsg)
    setErrName('');
    //setErrMsg('')
    let {name, value} = e.target;
    setFm({...fm, [name]:value})
    
  }   

  return (
    <>
    <Layout>
   <div>
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>Contact Us</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/contact" className="active breadcrumb_active">Contact</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>  
  {/*== Page Title Area End ==*/}
  {/*== Page Content Wrapper Start ==*/}
  <div id="page-content-wrapper" className="p-9">
    <div className="container">
      <div className="row">
        {/* Contact Page Content Start */}
        <div className="col-lg-12">
          {/* Contact Method Start */}
          <div className="contact-method-wrap">
            <div className="row">
              {/* Single Method Start */}
              <div className="col-lg-4 col-sm-6 text-center">
                <div className="contact-method-item">
                  <span className="method-icon"><i className="fa fa-map-marker" /></span>
                  <div className="method-info">
                    <h3>Street Address</h3>
                    <p>Address : 3208, 2nd Floor, Mahindra Park, <br /> Fountain Chowk,Near Pitampura, Delhi 110034</p>
                  </div>
                </div>
              </div>
              {/* Single Method End */}
              {/* Single Method Start */}
              <div className="col-lg-4 col-sm-6 text-center">
                <div className="contact-method-item">
                  <span className="method-icon"><i className="fa fa-phone" /></span>
                  <div className="method-info">
                    <h3>Phone Number</h3>
                    <a href="tel:+91 82877 66833">+91 82877 66833</a>
                    {/* <a href="tel:0(1234)56789012">0(1323) 466 89012</a> */}
                  </div>
                </div>
              </div>
              {/* Single Method End */}
              {/* Single Method Start */}
              {/* <div className="col-lg-3 col-sm-6 text-center">
                <div className="contact-method-item">
                  <span className="method-icon"><i className="fa fa-envelope-open-o" /></span>
                  <div className="method-info">
                    <h3>Number Fax</h3>
                    <p>+1 323 555 1234 <br /> +1 267 555 12342</p>
                  </div>
                </div>
              </div> */}
              {/* Single Method End */}
              {/* Single Method Start */}
              <div className="col-lg-4 col-sm-6 text-center">
                <div className="contact-method-item">
                  <span className="method-icon"><i className="fa fa-envelope" /></span>
                  <div className="method-info">
                    <h3>Email Address</h3>
                    <a href="mailto:mail@vivanhomes.com">mail@vivanhomes.com</a>
                    {/* <a href="mailto:your@email.here">your@email.here</a> */}
                  </div>
                </div>
              </div>
              {/* Single Method End */}
            </div>
          </div>
          {/* Contact Method End */}
        </div>
        {/* Contact Page Content End */}
      </div>
      <div className="row">
        {/* Contact Form Start */}
        <div className="col-lg-9 m-auto">
          <div className="contact-form-wrap">
            <h2>Request a Quote</h2>
            <form onSubmit={fmSubmit}>
              <div className="row">
                <div className="col-lg-6">
                <MyInput inputType="input" type="text" name="first_name" placeholder="First Name *" value={fm.first_name} onChnge={onFmChange} errName={errName} errMsg={errMsg} />
                </div>
                <div className="col-lg-6">
                <MyInput inputType="input" type="text" name="last_name" placeholder="Last Name *" value={fm.last_name} onChnge={onFmChange} errName={errName} errMsg={errMsg} />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                <MyInput inputType="input" type="text" name="email" placeholder="Email *" value={fm.email} onChnge={onFmChange} errName={errName} errMsg={errMsg} />
                </div>
                <div className="col-lg-6">
                <MyInput inputType="input" type="text" name="subject" placeholder="Subject *" value={fm.subject} onChnge={onFmChange} errName={errName} errMsg={errMsg} />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="single-input-item">
                    <textarea name="message" id="message" cols={30} rows={6} placeholder="Message *" value={fm.message}  onChange={onFmChange} />
                    {errName==="message" && errMsg!=='' ? <p style={{color:'#D82E2E'}}>{errMsg}</p> : '' }
                  </div>
                  <div className="single-input-item text-center">
                  <MyButton type="submit" name="submit" clas="btn-login" value="Submit" loading={loading} err={err} errMsg={contactMsg} />
                   
                  </div>
                  {/* Form Notification */}
                  <div className="form-messege" />  
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Contact Form End */}
      </div>
    </div>
  </div>
</div>
</Layout>
    </>
  )
}

export default Contact
import React from 'react'
import OptionalHeader from './OptionalHeader'

function OptionalLayout({children}) {
  return (
    <>
    <OptionalHeader />
    {children}
    
    </>
  )
}

export default OptionalLayout
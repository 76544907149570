import React from 'react'
import Layout from '../partials/Layout'
import Order from './dashboard/Order'
import Profile from './dashboard/Profile'
import UserDashboard from './dashboard/UserDashboard'

function Account() {
  return (
    <>
    <Layout>
<div>
  {/*== Page Title Area Start ==*/}
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>Dashboard</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/dashboard" className="active breadcrumb_active">Dashboard</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*== Page Title Area End ==*/}
  {/*== Page Content Wrapper Start ==*/}
  <div id="page-content-wrapper" className="p-9">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {/* My Account Page Start */}
          <div className="myaccount-page-wrapper">
            {/* My Account Tab Menu Start */}
            <div className="row">
              <div className="col-lg-3">
                <div className="myaccount-tab-menu nav" role="tablist">
                  {/* <a href="#dashboad" className="active" data-toggle="tab"><i className="fa fa-dashboard" />
                    Dashboard</a> */}
                  <a href="#orders" className="active" data-toggle="tab"><i className="fa fa-cart-arrow-down" /> Orders</a>
                  {/* <a href="#download" data-toggle="tab"><i className="fa fa-cloud-download" /> Download</a>
                  <a href="#payment-method" data-toggle="tab"><i className="fa fa-credit-card" /> Payment
                    Method</a> */}
                  {/* <a href="#address" data-toggle="tab"><i className="fa fa-map-marker" /> address</a> */}
                  <a href="#account-info" data-toggle="tab"><i className="fa fa-user" /> Account Details</a>
                  <a href="/log-out"><i className="fa fa-sign-out" /> Logout</a>
                </div>
              </div>
              {/* My Account Tab Menu End */}
              {/* My Account Tab Content Start */}
              <div className="col-lg-9 mt-5 mt-lg-0">
                <div className="tab-content" id="myaccountContent">
                  {/* Single Tab Content Start */}
                  <div className="tab-pane fade" id="dashboad" role="tabpanel">
                   <UserDashboard />
                  </div>
                  {/* Single Tab Content End */}
                  {/* Single Tab Content Start */}
                  <div className="tab-pane fade show active" id="orders" role="tabpanel">
                    <Order />
                  </div>
                  {/* Single Tab Content End */}
                  {/* Single Tab Content Start */}
                  <div className="tab-pane fade" id="download" role="tabpanel">
                    <div className="myaccount-content">
                      <h3>Downloads</h3>
                      <div className="myaccount-table table-responsive text-center">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th>Product</th>
                              <th>Date</th>
                              <th>Expire</th>
                              <th>Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Haven - Free Real Estate PSD Template</td>
                              <td>Aug 22, 2018</td>
                              <td>Yes</td>
                              {/* <td><a href="#" className="btn-add-to-cart">Download File</a></td> */}
                            </tr>
                            <tr>
                              <td>HasTech - Profolio Business Template</td>
                              <td>Sep 12, 2018</td>
                              <td>Never</td>
                              {/* <td><a href="#" className="btn-add-to-cart">Download File</a></td> */}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Single Tab Content End */}
                  {/* Single Tab Content Start */}
                  <div className="tab-pane fade" id="payment-method" role="tabpanel">
                    <div className="myaccount-content">
                      <h3>Payment Method</h3>
                      <p className="saved-message">You Can't Saved Your Payment Method yet.</p>
                    </div>
                  </div>
                  {/* Single Tab Content End */}
                  {/* Single Tab Content Start */}
                  <div className="tab-pane fade" id="address" role="tabpanel">
                    <div className="myaccount-content">
                      <h3>Billing Address</h3>
                      <address>
                        <p><strong>Alex Tuntuni</strong></p>
                        <p>1355 Market St, Suite 900 <br />
                          San Francisco, CA 94103</p>
                        <p>Mobile: (123) 456-7890</p>
                      </address>
                      {/* <a href="#" className="btn-add-to-cart d-inline-block"><i className="fa fa-edit" />
                        Edit Address</a> */}
                    </div>
                  </div>
                  {/* Single Tab Content End */}
                  {/* Single Tab Content Start */}
                  <div className="tab-pane fade" id="account-info" role="tabpanel">
                  <Profile />
                  </div>
                  {/* Single Tab Content End */}
                </div>
              </div>
              {/* My Account Tab Content End */}
            </div>
          </div>
          {/* My Account Page End */}
        </div>
      </div>
    </div>
  </div>
  {/*== Page Content Wrapper End ==*/}
</div>
  
    </Layout>
    </>
  )
}

export default Account
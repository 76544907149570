import { useEffect} from 'react'
import api from '../../actions/api'
import { toast } from 'react-toastify';

const Logout = () => {
    
    useEffect(() => {
        signOut();
    }, [])
    
    const signOut = async ()=>{
        try{
            let resp = {
                'path':'user-logout'
            };
            console.log(resp)
            let res = await api.get(resp.path);
            console.log(res.data.error)
            if (res.data.error===false) {
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('user');
                window.location.href = '/'; 
            }
            else{
                toast("Please refresh and try again", {
                    position: "top-right",
                    autoClose: 3000,
                    type:'error'
                })
            }
        }
        catch(error)
        {
            console.log(error)
            
        }
    }
}

export default Logout

import React, { useEffect, useState } from 'react'
import Layout from '../partials/Layout'
import { Link, useParams } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux'
import { getData, userCart } from '../../actions/Useraction';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Product() {
  const {product} = useParams();
  const dispatch = useDispatch();
  const [pr, setPr] = useState();
  const [images, setImages] = useState([]);
  const [rating, setRating] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(()=>{
    //console.log("category", category)
  //fetchCategories()
  fetchProduct()
}, [dispatch])

const handleAddToCart = async(id)=>{
  try{
    var resp = {
      pth:'add-to-cart',
      data:{
        prd:id
      }
    }
    await dispatch(userCart(resp))
    
  }
  catch (error) {
    console.error('Error fetching products:', error, error);
  }
}


const fetchProduct = async () => {
  try {
    let pth = `get-product/${product}`;
    console.log(pth)
    const data = await dispatch(getData(pth));
    console.log("rec ", data.payload.output);
    if(data?.payload?.output)
    {
    setPr(data?.payload?.output)
    setImages(data?.payload?.output?.images)
    }
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

const renderThumbs = () => {
  return images.map((im) => (
    <img src={im?.imag} key={im?.id} alt="Thumbnail" className="img-fluid thumb-img" />
  ));
};

  return (
    <>
    <Layout>
<div>
  {/*== Page Title Area Start ==*/}
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/shop">Shop</a></li>
              <li><a href="/" className="active breadcrumb_active">{pr?.name}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*== Page Title Area End ==*/}
  {/*== Page Content Wrapper Start ==*/}
  <div id="page-content-wrapper" className="p-9">
    <div className="ruby-container">
      <div className="row">
        {/* Single Product Page Content Start */}
        <div className="col-lg-12">
          <div className="single-product-page-content">
            <div className="row">
            
              <div className="col-lg-5">
                <div className="product-thumbnail-wrap">
                  <div className="">
                  <Carousel autoPlay="true" infiniteLoop="true" emulateTouch="true" renderThumbs={renderThumbs} showThumbs={true} dynamicHeight={true} key={imageLoaded}>
                {images?.length > 0 ? (
          images?.map((im, index) => (
            <div className="single-thumb-item" key={index}>
              <a href="single-product.html">
                <img className="img-fluid" src={im?.imag} alt="Product" onLoad={() => setImageLoaded(true)} />
              </a>
            </div>  
          ))
        ) : (
          <p>Product image</p>
        )}
        </Carousel>
        
                  </div>
                </div>
              </div>
              {/* Product Thumbnail End */}
              {/* Product Details Start */}
              <div className="col-lg-7 mt-5 mt-lg-0">
                <div className="product-details">
                  <h2>{pr?.name}</h2>
                  {/* <div className="rating">
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star-half" />
                    <i className="fa fa-star-o" />
                  </div> */}
                  <span className="price">{pr?.price != null ? '₹' + pr?.price : ''} 
                  {pr?.old_price && pr?.old_price !== 0.0 && (
                                <>
                                  (<s style={{ fontSize: '12px' }}> ₹{pr?.old_price}</s>)
                                </>
                              )}
                  
                     </span>
                  <div className="product-info-stock-sku">
                  {
                            pr?.stock > 0 ? <span className="text-success">In Stock</span> : <span className="text-danger">Out of Stock</span>
                          }

     
                    {/* <span className="product-sku-status ml-5"><strong>SKU</strong> MH03</span> */}
                  </div>
                  <p className="products-desc" dangerouslySetInnerHTML={{ __html: pr?.short_desc }}></p>
                  <div className="product-quantity mt-5 d-flex align-items-center">
                    {/* <div className="quantity-field">
                      <label htmlFor="qty">Qty</label>
                      <input type="number" id="qty" min={1} max={100} defaultValue={1} />
                    </div> */}
                    {
                          pr?.stock > 0 ? <Link onClick={()=>handleAddToCart(pr?.id)} className="btn btn-add-to-cart">+ Add to Cart</Link> : <Link className="btn btn-add-to-cart disable">+ Add to Cart</Link>
                        }
                  </div>
                  {/* <div className="product-btn-group d-none d-sm-block">
                    <a href="single-product.html" className="btn btn-add-to-cart btn-whislist">+ Add to
                      Wishlist</a>
                    <a href="single-product.html" className="btn btn-add-to-cart btn-whislist">+ Add to
                      Compare</a>
                    <a href="single-product.html" className="btn btn-add-to-cart btn-compare">Email</a>
                  </div> */}
                </div>
              </div>
              {/* Product Details End */}
            </div>
            <div className="row">
              <div className="col-lg-12">
                {/* Product Full Description Start */}
                <div className="product-full-info-reviews">
                  {/* Single Product tab Menu */}
                  <nav className="nav" id="nav-tab">
                  <a id="description-tab" data-toggle="tab" href="#description">Description</a>
                    {/* <a className="active" id="description-tab" data-toggle="tab" href="#description">Description</a> */}
                    {/* <a id="reviews-tab" data-toggle="tab" href="#reviews">Reviews</a> */}
                  </nav>
                  {/* Single Product tab Menu */}
                  {/* Single Product tab Content */}
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="description">
                    <p dangerouslySetInnerHTML={{ __html: pr?.desc }}></p>
                      
                    </div>
                    <div className="tab-pane fade" id="reviews">
                      <div className="row">
                        <div className="col-lg-7">
                          <div className="product-ratting-wrap">
                            <div className="pro-avg-ratting">
                              <h4>4.5 <span>(Overall)</span></h4>
                              <span>Based on 9 Comments</span>
                            </div>
                            <div className="ratting-list">
                              <div className="sin-list float-left">
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <span>(5)</span>
                              </div>
                              <div className="sin-list float-left"> 
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star-o" />
                                <span>(3)</span>
                              </div>
                              <div className="sin-list float-left">
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star-o" />
                                <i className="fa fa-star-o" />
                                <span>(1)</span>
                              </div>
                              <div className="sin-list float-left">
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star-o" />
                                <i className="fa fa-star-o" />
                                <i className="fa fa-star-o" />
                                <span>(0)</span>
                              </div>
                              <div className="sin-list float-left">
                                <i className="fa fa-star" />
                                <i className="fa fa-star-o" />
                                <i className="fa fa-star-o" />
                                <i className="fa fa-star-o" />
                                <i className="fa fa-star-o" />
                                <span>(0)</span>
                              </div>
                            </div>
                            <div className="rattings-wrapper">
                              <div className="sin-rattings">
                                <div className="ratting-author">
                                  <h3>Cristopher Lee</h3>
                                  <div className="ratting-star">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <span>(5)</span>
                                  </div>
                                </div>
                                <p>enim ipsam voluptatem quia voluptas sit aspernatur aut
                                  odit aut fugit, sed quia res eos qui ratione voluptatem
                                  sequi Neque porro quisquam est, qui dolorem ipsum quia
                                  dolor sit amet, consectetur, adipisci veli</p>
                              </div>
                              <div className="sin-rattings">
                                <div className="ratting-author">
                                  <h3>Nirob Khan</h3>
                                  <div className="ratting-star">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <span>(5)</span>
                                  </div>
                                </div>
                                <p>enim ipsam voluptatem quia voluptas sit aspernatur aut
                                  odit aut fugit, sed quia res eos qui ratione voluptatem
                                  sequi Neque porro quisquam est, qui dolorem ipsum quia
                                  dolor sit amet, consectetur, adipisci veli</p>
                              </div>
                              <div className="sin-rattings">
                                <div className="ratting-author">
                                  <h3>MD.ZENAUL ISLAM</h3>
                                  <div className="ratting-star">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <span>(5)</span>
                                  </div>
                                </div>
                                <p>enim ipsam voluptatem quia voluptas sit aspernatur aut
                                  odit aut fugit, sed quia res eos qui ratione voluptatem
                                  sequi Neque porro quisquam est, qui dolorem ipsum quia
                                  dolor sit amet, consectetur, adipisci veli</p>
                              </div>
                            </div>
                            <div className="ratting-form-wrapper fix">
                              <h3>Add your Comments</h3>
                              <form action="#" method="post">
                                <div className="ratting-form row">
                                  <div className="col-12 mb-4">
                                    <h5>Rating:</h5>
                                    <div className="ratting-star fix">
                                      <i className="fa fa-star-o" />
                                      <i className="fa fa-star-o" />
                                      <i className="fa fa-star-o" />
                                      <i className="fa fa-star-o" />
                                      <i className="fa fa-star-o" />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12 mb-4">
                                    <label htmlFor="name">Name:</label>
                                    <input id="name" placeholder="Name" type="text" />
                                  </div>
                                  <div className="col-md-6 col-12 mb-4">
                                    <label htmlFor="email">Email:</label>
                                    <input id="email" placeholder="Email" type="text" />
                                  </div>
                                  <div className="col-12 mb-4">
                                    <label htmlFor="your-review">Your Review:</label>
                                    <textarea name="review" id="your-review" placeholder="Write a review" defaultValue={""} />
                                  </div>
                                  <div className="col-12">
                                    <input defaultValue="add review" type="submit" />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Single Product tab Content */}
                </div>
                {/* Product Full Description End */}
              </div>
            </div>
          </div>
        </div>
        {/* Single Product Page Content End */}
      </div>
    </div>
  </div>
  {/*== Page Content Wrapper End ==*/}
</div>

    </Layout>
    </>
  )
}

export default Product
import React, { useCallback, useEffect, useState } from 'react'
import Search from './Search'
import { Link, useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { getCart, userCart } from '../../actions/Useraction';

function Header() {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true' || false;
  const location = useLocation();
  const [cpth, setCpth] = useState('');
  const dispatch = useDispatch();
  const state = useSelector((state)=>{
    return state.usr;
  })

  const getCartDetails = useCallback(async () => {
   console.log("isLoggedIn", isLoggedIn)
    try {
      if (isLoggedIn) {
        await dispatch(getCart('get-cart-details'));
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }, [isLoggedIn, dispatch]);

  useEffect(()=>{
    //console.log(localStorage.getItem('isLoggedIn'))
    //console.log("params", location.pathname);
    setCpth(location.pathname)
    getCartDetails()
  },[location.pathname, getCartDetails])


  // const getCartDetails = async ()=>{
  //   try {
  //    if(isLoggedIn)
  //    {
  //     await dispatch(getCart('get-cart-details'));
  //   }
      
  //   } catch (error) {
  //     console.error('Error fetching categories:', error);
  //   }
  // }
  

  const removeCart = async(id)=>{
    //console.log("Item added to cart");
    if(localStorage.getItem('isLoggedIn'))
      {
    try{
      var resp = {
        pth:'remove-cart',
        data:{
          cart_id:id
        }
      }
      await dispatch(userCart(resp))
    }
    catch (error) {
      console.error('Error in removing item:', error);
    }
  }
  }


  return (
    <>

<div className="top-info-bar">
  <div className="container">
    <div className="row align-items-center">
      {/* Social Icons */}
      <div className="col-12 col-sm-4 col-md-4 col-lg-2 d-flex justify-content-center justify-content-lg-start mb-2 mb-sm-0">
        <div className="social-icons list-inline">
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a href="https://www.facebook.com/profile.php?id=61563942341725" target="_blank">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://in.pinterest.com/01i5lixr11r0ha3lisfix8bsali7fx/" target="_blank">
                <i className="fa fa-pinterest-p text-white" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.instagram.com/vivanhomes/" target="_blank">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://wa.me/918287766833" target="_blank">
                <i className="fa fa-whatsapp text-white" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Tagline */}
      <div className="col-12 col-sm-4 col-md-4 col-lg-8 text-center">
        <p className="m-0 fw-bold">
          <img src="/assets/img/slider/Tag Line White Small.png" alt="Tagline" className="img-fluid" />
        </p>
      </div>

      {/* Contact Us */}
      <div className="col-12 col-sm-4 col-md-4 col-lg-2 d-flex justify-content-center justify-content-lg-end">
      {
                  isLoggedIn ? (
                    <>
                      <a href="/log-out" className="text-white fw-bold" style={{fontWeight: 'bold', fontSize:'15px'}}>Log-out</a>
                    </>
                  ) : (
                    <a href="/contact" className="text-white fw-bold" style={{fontWeight: 'bold', fontSize:'15px'}}>Contact Us</a>
                  )
                }
        
      </div>
    </div>
  </div>
</div>

     <header id="header-area" className="header__3">
    <div className="ruby-container" style={{padding: '0 4%'}}>
      <div className="row">
        {/* Logo Area Start */} 
        <div className="col-3 col-lg-1 col-xl-2 m-auto">
          <a href="/" className="logo-area">
            <img src="/assets/img/logo_vh1_web.jpg" alt="Logo" className="img-fluid" style={{height:'auto', width:'70px'}} />
          </a>
        </div>
        {/* Logo Area End */}
        {/* Navigation Area Start */}     
        <div className="col-3 col-lg-9 col-xl-8 m-auto">
          <div className="main-menu-wrap">
            <nav id="mainmenu">
              <ul>  
          
              <li><a href="/" className={cpth === '/' ? 'activve' : ''}>Home</a></li>
              <li><a href="/about" className={cpth === '/about' ? 'activve' : ''}>About</a></li>
              <li><a href="/shop" className={cpth === '/shop' ? 'activve' : ''}>Shop</a></li>
              {/* <li><a href="/shop" className={cpth === '/contact.html' ? 'active' : ''}>New Arrivals</a></li> */}
              <li><a href="/contact" className={cpth === '/contact' ? 'activve' : ''}>Contact Us</a></li>
                {
                  isLoggedIn ? (
                    <>
                      <li><a href="/dashboard" className={cpth === '/dashboard' ? 'activve' : ''}>Dashboard</a></li>
                      {/* <li><a href="/log-out">Log-out</a></li> */}
                    </>
                  ) : (
                    <li><a href="/login-signup" className={cpth === '/login-signup' ? 'activve' : ''}>Login/Signup</a></li>
                  )
                }
                
              </ul>
            </nav>
          </div>
        </div>
        {/* Navigation Area End */}
        {/* Header Right Meta Start */}
        <div className="col-6 col-lg-2 m-auto">
          <div className="header-right-meta text-right">
            <ul>
              {/* <li><a href="#" className="modal-active"><i className="fa fa-search" /></a></li>   */}
              { isLoggedIn ? <li><a href="/wishlist" ><i className="fa fa-heart" /> </a></li> : <></>} 
              <li className="shop-cart">
              { isLoggedIn ?   <a href='/cart'><i className="fa fa-shopping-bag" /> 
                
                {state?.total_cart_item!=='' ? 
                <span className="count">{state?.total_cart_item}</span>
                : <></>}
                </a> : <></>} 

                { state?.total_cart_item > 0 ? 
                <div className="mini-cart">
                  <div className="mini-cart-body">
                  {
                  state?.cart?.length > 0 ? state?.cart?.map((pr, index)=>(
                    <div className="single-cart-item d-flex" key={index}>
                    <figure className="product-thumb">  
                      <Link><img className="img-fluid" src={pr?.cart_dtl?.image} alt="Products" /></Link>
                    </figure>
                    <div className="product-details">
                      <h2><Link>{pr?.cart_dtl?.name}</Link></h2>
                      <div className="cal d-flex align-items-center">
                        <span className="quantity">{pr?.cart_dtl?.qty}</span>
                        <span className="multiplication">X</span>
                        <span className="price">₹ {pr?.cart_dtl?.qty * pr?.cart_dtl?.price}</span>
                      </div>
                    </div>
                    <Link className="remove-icon" onClick={()=>removeCart(pr?.cart_dtl?.id)} ><i className="fa fa-trash-o" /></Link>
                  </div>
                  )) : <></> }
                    
                   
                  </div>
                  <div className="mini-cart-footer">
                    <a href="/cart" className="btn-add-to-cart">View Cart</a>
                  </div>
                </div>
                : <></> }
              </li>
            </ul>
          </div>
        </div>
        {/* Header Right Meta End */}
      </div>
    </div>
  </header>
  <Search />
    </>
  )
}

export default Header
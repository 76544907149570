import {createSlice} from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { register, userOTP, userNormalRoute, login, getData, userData, userCart, getUserData, getCart, updateCartDtl, userWishlist, getWishlist } from '../actions/Useraction';


const initialState = {
    loading:false,
    error:true,
    msg:"",
    cart:{},
    total_cart_item:"",
    total_cart_amt:"",
    total_payable_item:0,
    wishlist:{},
    shippingAddress:{},
    data:[],
    total:"",   
    user_email:{}
}   

const Userprovider = createSlice({
    name:'user',
    initialState:initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(login.pending, (state)=>{
            console.log("pending ", state)
            state.loading=true;
        });
        builder.addCase(login.fulfilled, (state, {payload})=>{
           console.log("login output", payload, payload.error)
           state.loading=false;
           state.data=payload;
           if(payload.error===false)
           {
            //console.log("token ", payload.access)
            localStorage.setItem('token', payload.access);
            //localStorage.setItem('user', payload.user);
            localStorage.setItem('isLoggedIn', true);
            
            const referrerUrl = document.referrer;
            if (referrerUrl) {
                const referrerHostname = new URL(referrerUrl).hostname;
                const currentHostname = window.location.hostname;
                if(referrerHostname===currentHostname)
                {
                    window.location.href = referrerUrl;    
                }
                else
                {
                    window.location.href = '/';
                }
                //console.log("Referrer Hostname:", referrerHostname);
            } else {
                window.location.href = '/';
            }
            //window.location.href = '/'; 
        } 
           else{
            localStorage.setItem('isLoggedIn', false);
           }
        });
        builder.addCase(login.rejected, (state, {payload})=>{
            console.log("rejected ", state, payload)
              state.loading=false;
            // toast(payload.msg, {
            //     position: "top-right",
            //     autoClose: 3000,
            //     type:'error'
            // })
        });
        builder.addCase(register.pending, (state)=>{
            console.log("pending ", state)
            state.loading=true;
        });
        builder.addCase(register.fulfilled, (state, {payload})=>{
            console.log("fulfilled output", payload, payload.error)
            state.loading=false;
         });
         builder.addCase(register.rejected, (state, {payload})=>{
             console.log("rejected ", state, payload)
               state.loading=false;
         });
         builder.addCase(userOTP.pending, (state)=>{
            console.log("pending ", state)
            state.loading=true;
        });
        builder.addCase(userOTP.fulfilled, (state, {payload})=>{
            console.log("fulfilled output", payload, payload.error)
            state.loading=false;
            state.error=payload.error;
            state.msg=payload.msg
         });
         builder.addCase(userOTP.rejected, (state, {payload})=>{
             console.log("rejected ", state, payload)
               state.loading=false;
         });

         builder.addCase(userNormalRoute.pending, (state)=>{
            console.log("pending ", state)
            state.loading=true;
        });
        builder.addCase(userNormalRoute.fulfilled, (state, {payload})=>{
            console.log("fulfilled output", payload, payload.error)
            state.loading=false;
            // state.error=payload.error;
            // state.msg=payload.msg
         });
         builder.addCase(userNormalRoute.rejected, (state, {payload})=>{
             console.log("rejected ", state, payload)
               state.loading=false;
         }); 
         builder.addCase(getData.pending, (state)=>{
            //console.log("pending ", state)
            state.loading=true;
        });
        builder.addCase(getData.fulfilled, (state, {payload})=>{
           console.log("fulfilled output", payload, payload.error)
           state.data=payload;
           
           //state.total = payload.total
        });
        builder.addCase(getData.rejected, (state, {payload})=>{
            console.log("rejected ", state, payload)
            //  state.loading=false;
            // toast(payload.msg, {
            //     position: "top-right",
            //     autoClose: 3000,
            //     type:'error'
            // })
        });
        builder.addCase(userData.pending, (state)=>{
            console.log("pending ", state)
            state.loading=true;
        });
        builder.addCase(userData.fulfilled, (state, {payload})=>{
            console.log("fulfilled output", payload, payload.error)
            state.data=payload;
           // state.loading=false;
           
         });
         builder.addCase(userData.rejected, (state, {payload})=>{
                 console.log("rejected ", state, payload)
                   state.loading=false;
             }); 

             builder.addCase(getUserData.pending, (state)=>{
                console.log("pending ", state)
                state.loading=true;
            });
            builder.addCase(getUserData.fulfilled, (state, {payload})=>{
                console.log("fulfilled output", payload, payload.error)
                //state.data=payload;
               // state.loading=false;
               
             });
             builder.addCase(getUserData.rejected, (state, {payload})=>{
                     console.log("rejected ", state, payload)
                       state.loading=false;
                 });      
                 builder.addCase(getCart.pending, (state)=>{
                    //console.log("pending ", state)
                    state.loading=true;
                });
                builder.addCase(getCart.fulfilled, (state, {payload})=>{
                    console.log("fulfilled cart", payload, payload.error)
                    state.cart=payload.cart;
                    state.total_cart_item=payload.total_cart_item;
                    state.total_cart_amt = payload.total_amt;
                    state.total_payable_item = payload.total_payable_item;
                    state.loading=false;
                   
                 });
                 builder.addCase(getCart.rejected, (state, {payload})=>{
                         console.log("rejected ", state, payload)
                           state.loading=false;
                           if(payload.detail==='Authentication credentials were not provided.')
                            { 
                             toast("Please Login", {
                                 position: "top-right",
                                 autoClose: 3000,
                                 type:'error'
                             })     
                            }
                            else if(payload.code==='token_not_valid')
                            {
                             localStorage.removeItem('token');
                             localStorage.removeItem('isLoggedIn');
                             window.location.href = '/login-signup'; 
                            }
                        }); 

        builder.addCase(userCart.pending, (state, {payload})=>{
             console.log("pending ", state, payload)
               //state.loading=false;
         
            });
         builder.addCase(userCart.fulfilled, (state, {payload})=>{
            console.log("fulfilled output", payload, payload.error)
            state.data=payload;
           // state.loading=false;
           state.cart=payload.cart;
            state.total_cart_item=payload.total_cart_item;
            state.total_cart_amt = payload.total_amt;
            state.total_payable_item = payload.total_payable_item;
           toast(payload.msg, {
            position: "top-right",
            autoClose: 3000,
            type:payload.error ? 'warning' : 'success'  
            })
         });
         builder.addCase(userCart.rejected, (state, {payload})=>{   
             console.log("rejected ", state, payload)
               //state.loading=false;
               if(payload.detail==='Authentication credentials were not provided.')
                { 
                 toast("Please Login", {
                     position: "top-right",
                     autoClose: 3000,
                     type:'error'
                 })     
                }
                else if(payload.code==='token_not_valid')
                {
                 localStorage.removeItem('token');
                 localStorage.removeItem('isLoggedIn');
                 window.location.href = '/login-signup'; 
                }
         });

         builder.addCase(updateCartDtl.pending, (state, {payload})=>{
            console.log("pending ", state, payload)
              //state.loading=false;
        
           });
        builder.addCase(updateCartDtl.fulfilled, (state, {payload})=>{
           console.log("fulfilled output", payload, payload.error)
           state.data=payload;
          // state.loading=false;
          state.cart=payload.cart;
           state.total_cart_item=payload.total_cart_item;
           state.total_cart_amt = payload.total_amt;
           state.total_payable_item = payload.total_payable_item;
        //   toast(payload.msg, {
        //    position: "top-right",
        //    autoClose: 3000,
        //    type:payload.error ? 'warning' : 'success'
        //    })
        });
        builder.addCase(updateCartDtl.rejected, (state, {payload})=>{
            console.log("rejected ", state, payload)
              //state.loading=false;
              if(payload.detail==='Authentication credentials were not provided.')
               { 
                toast("Please Login", {
                    position: "top-right",
                    autoClose: 3000,
                    type:'error'
                })     
               }
               else if(payload.code==='token_not_valid')
               {
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedIn');
                window.location.href = '/login-signup'; 
               }
        });
           
         /// wishlist

         builder.addCase(userWishlist.pending, (state, {payload})=>{
            console.log("pending ", state, payload)
              //state.loading=false;
        
           });
        builder.addCase(userWishlist.fulfilled, (state, {payload})=>{
           console.log("fulfilled output", payload, payload.error)
           state.data=payload;
          // state.loading=false;
          state.wishlist=payload.wishlist;
        
          toast(payload.msg, {
           position: "top-right",
           autoClose: 3000,
           type:payload.error ? 'warning' : 'success'
           })
        });
        builder.addCase(userWishlist.rejected, (state, {payload})=>{
            console.log("rejected ", state, payload)
              //state.loading=false;
              if(payload.detail==='Authentication credentials were not provided.')
               { 
                toast("Please Login", {
                    position: "top-right",
                    autoClose: 3000,
                    type:'error'
                })     
               }
               else if(payload.code==='token_not_valid')
               {
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedIn');
                window.location.href = '/login-signup'; 
               }
        });
        builder.addCase(getWishlist.pending, (state)=>{
            //console.log("pending ", state)
            //state.loading=true;
        });
        builder.addCase(getWishlist.fulfilled, (state, {payload})=>{
            console.log("fulfilled wishlist", payload, payload.error)
            state.wishlist=payload.wishlist;
           
            //state.loading=false;
           
         });
         builder.addCase(getWishlist.rejected, (state, {payload})=>{
                 console.log("rejected ", state, payload)
           //        state.loading=false;
                   if(payload.detail==='Authentication credentials were not provided.')
                    { 
                     toast("Please Login", {
                         position: "top-right",
                         autoClose: 3000,
                         type:'error'
                     })     
                    }
                    else if(payload.code==='token_not_valid')
                    {
                     localStorage.removeItem('token');
                     localStorage.removeItem('isLoggedIn');
                     window.location.href = '/login-signup'; 
                    }
                });

    }

})

export default Userprovider




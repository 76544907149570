import React, { useEffect, useState } from 'react'
import { loadScript } from './Utils';
import api from '../../../actions/api';
import { toast } from 'react-toastify';

function RazorPayment({amount,products,orderId,activateRazor,type,name,cls,value,err,errMsg,user,loading,...props}) 
{
  const userName = `${user?.first_name} ${user?.last_name}`;
  const [payment, setPayment] = useState(false);
  const [perr, setPerr] = useState(true);
  const [orderMsg, setOrderMsg] = useState("");

  useEffect(() => {
    if (activateRazor && user && orderId) {
      handlePayment();
    }
  }, [activateRazor, user, orderId, payment]);

  const productDescription = products.map(pr => `${pr?.name} × ${pr?.qty}`).join(', ');

  const handlePayment = () => {
    loadRazorpay(); 
  };

  const loadRazorpay = async () => {
    await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    
    const options = {
      key: 'rzp_live_zjkjq4NyHiTylS', // rzp_test_uyjwTZ4f2Hw97d      rzp_live_zjkjq4NyHiTylS
      amount: amount * 100,
      image: '/assets/img/vivan_logo_1.png',
      currency: 'INR',
      theme: { color: '#B89C7D' },
      name: userName,
      description: 'Payment for Purchase',
      notes: { "product_details": productDescription },
      handler: (response) => {
        console.log(response);
        placeOrder(response.razorpay_payment_id)
      },
      prefill: {
        name: userName,
        email: user?.email || '', 
        contact: user?.contact || '',
      },
      modal: {
        ondismiss: () => {
          console.log('Payment dismissed');
        },
      },
    };

    const razorpayInstance = new window.Razorpay(options);
    razorpayInstance.open();
  };

  const placeOrder = async (payPaymentId)=>{
    try {
      const headers = {
        'Accept': 'application/json',
      };
      var formData = {
        'orderId':orderId,
        'paymentId':payPaymentId
      }
      const res = await api.post('place-order', formData, {headers});
       console.log("order info ", res.data.msg); 
       setPayment(res.data.error)
       if(res.data.error)
       { 
        toast(res.data.msg, {
          position: "top-right",
          autoClose: 3000,
          type:res.data.error ? 'warning' : 'success'
      })
      return;
       }
       else
       {
        toast(res.data.msg, {
          position: "top-right",
          autoClose: 3000,
          type:res.data.error ? 'warning' : 'success'
      })
      if (!res.data.error) {
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      }

       }
       
    } catch (error) {
      console.error('Error in placeing order:', error, error.message);
  }
  }


  return (
    (() => {
      if (payment) {
        return (
          <>
            <button type='button' name='submit' className='btn-add-to-cart'>{value}</button>
            {orderMsg !== '' ? (
              <p style={{ textAlign: 'center', color: perr ? '#D82E2E' : 'green' }}><br />{orderMsg}</p>
            ) : null}
          </>
        );
      } else {
        return (
          <>
          <button type={type} name={name} className={cls} disabled={loading} {...props}>{loading ? 'Please Wait...' : value}</button>
          {props.errMsg !== '' ? (
            <p style={{ textAlign: 'center', color: err ? '#D82E2E' : 'green' }}><br />{errMsg}</p>
          ) : null}
        </>
        );
      }
    })()
  );
  
}
export default RazorPayment
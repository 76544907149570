import React, { useEffect, useState } from 'react'
import Layout from '../partials/Layout'
import QuickView from '../partials/QuickView'
import {useDispatch} from 'react-redux'
import { getData } from '../../actions/Useraction';
import { useParams } from 'react-router-dom';
import papi from '../../actions/papi';

function Celebration() {
const [categories, setCategories] = useState([]);
const [products, setProducts] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(0);
const [itemsPerPage, setItemsPerPage] = useState(9);
const [loading, setLoading] = useState(false);

const [catname, setCatName] = useState('');
const {category} = useParams();
const dispatch = useDispatch();
const [prdt, setPrdt] = useState();

useEffect(()=>{
    //console.log("category", category)
  fetchCategories()
  fetchProducts(currentPage, itemsPerPage)
}, [dispatch, currentPage, itemsPerPage])

const fetchCategories = async () => {
  try {
    const data = await dispatch(getData('get-celebration-data'));
    console.log("categories ", data.payload.output);
    let catg = data.payload.output
    const foundCategory = catg.find(ctg => ctg.slug === category);
    if (foundCategory) {
        setCatName(foundCategory.title);
      }
      else{
        setCatName(category)
      }
    setCategories(catg)
    
  } catch (error) {
    console.error('Error fetching categories:', error, error.response.data);
  }
};

function overview(prt)
{
  console.log(prt)
  setPrdt(prt);
}

const handlePageChange = (page) => {
  if (page > 0 && page <= totalPages) {
    setCurrentPage(page);
  }
};

const handleItemsPerPageChange = (event) => {
  setItemsPerPage(parseInt(event.target.value));
  setCurrentPage(1); // Reset to first page when items per page changes
};

const fetchProducts = async (page = 1, perPage = itemsPerPage) => {
  try {
    setLoading(true); 
    const headers = {
      'Accept': 'application/json',
    };
    const response = await papi.get(`get-celebration-products/${category}`, { 
      headers,
      params: { page, page_size: perPage } 
    });
    console.log("fetched products", response.data.results);
    setProducts(response?.data?.results);
    setTotalPages(Math.ceil(response?.data?.count / perPage));
  } catch (error) {
    console.error('Error fetching products:', error);
  } finally {
    setLoading(false); 
  }
};


  return (
    <>
    <Layout>
<div>
  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>Shop</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/shop">Shop</a></li>
              <li><a href="index.html" className="active breadcrumb_active">{catname}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*== Page Title Area End ==*/}
  {/*== Page Content Wrapper Start ==*/}
  <div id="page-content-wrapper" className="p-9">
    <div className="container">
      <div className="row">
        {/* Sidebar Area Start */}
        <div className="col-lg-3 mt-5 mt-lg-0 order-last order-lg-first">
          <div id="sidebar-area-wrap">
            {/* Single Sidebar Item Start */}
            <div className="single-sidebar-wrap">
              <h2 className="sidebar-title">Shop By</h2>
              <div className="sidebar-body">
                <div className="shopping-option">
                  {/* <h3>Shopping Options</h3> */}
      
                  <div className="shopping-option-item">
                    <h4>Categories</h4>
                    <ul className="sidebar-list">
                    {
                      categories.length > 0 ? categories.map((ct, index)=>(
                        <li key={index}><a href={`/celebration/${ct?.slug}`}>{ct?.title}</a></li>
                      )) : <></>
                    }
                      
                   
                    </ul>
                  </div>
            
                </div>
              </div>
            </div>
            {/* Single Sidebar Item End */}
    
            {/* Single Sidebar Item End */}
          </div>
        </div>
        {/* Sidebar Area End */}
        {/* Shop Page Content Start */}
        <div className="col-lg-9">
          <div className="shop-page-content-wrap">
            <div className="products-settings-option d-block d-md-flex">
              <div className="product-cong-left d-flex align-items-center">
                <ul className="product-view d-flex align-items-center">
                  <li className="current column-gird"><i className="fa fa-bars fa-rotate-90" /></li>
                  <li className="box-gird"><i className="fa fa-th" /></li>
                  <li className="list"><i className="fa fa-list-ul" /></li>
                </ul>
                <span className="show-items">Items {products.length>0 ? products.length : 0}</span>
              </div>
          
            </div>
            <div className="shop-page-products-wrap">
              <div className="products-wrapper">
                <div className="row">

                {
                  loading ? <div className="loader-container">
                  <img src="/assets/img/loader.svg" alt="loader" />
                </div> :

                  products.length > 0 ? products.map((pr, index)=>(
                    <div className="col-lg-4 col-sm-6" key={index}>
                    {/* Single Product Item */}
                    <div className="single-product-item text-center">
                      <figure className="product-thumb">
                        <a href={`/shop/${pr?.category}/${pr?.slug}`}><img src={pr?.imag} alt="Products" className="img-fluid" /></a>
                      </figure>
                      <div className="product-details">
                        <h2><a href={`/shop/${pr?.category}/${pr?.slug}`} title={pr?.name}>
                        {pr?.name?.length > 21 ? `${pr.name.slice(0, 21)}...` : pr?.name}
                        
                        </a></h2>
                     
                        <span className="price">₹ {pr?.price}</span> 
                        <p className="products-desc" dangerouslySetInnerHTML={{ __html: pr?.short_desc }}></p>
                        <a href="/cart" className="btn btn-add-to-cart">+ Add to Cart</a>
                        <a href="single-product.html" className="btn btn-add-to-cart btn-whislist">+
                          Wishlist</a>
                        {/* <a href="single-product.html" className="btn btn-add-to-cart btn-compare">+
                          Compare</a> */}
                      </div>
                      <div className="product-meta">
                        <button type="button" data-toggle="modal" data-target="#quickView">
                          <span data-toggle="tooltip" onClick={()=>overview(pr)} data-placement="left" title="Quick View"><i className="fa fa-compress" /></span>
                        </button>
                        <a href="/wishlist" data-toggle="tooltip" data-placement="left" title="Add to Wishlist"><i className="fa fa-heart-o" /></a>
                        {/* <a href="#" data-toggle="tooltip" data-placement="left" title="Compare"><i className="fa fa-tags" /></a> */}
                      </div>
                      {/* <span className="product-bedge">New</span> */}
                    </div>
                
                  </div>                    
                  )) : <div className="col-lg-4 col-sm-6">
                    <p>There is no product</p>
                  </div>
                }
 
                </div>
              </div>
            </div>
            {
              products?.length > 0 ?
            
              <div className="products-settings-option d-block d-md-flex">
              <nav className="page-pagination">
                <ul className="pagination">
                  <li>
                    <a
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      «
                    </a>
                  </li>
                  {[...Array(totalPages)].map((_, i) => (
                    <li key={i}>
                      <a
                        className={currentPage === i + 1 ? 'current' : ''}
                        href="#"
                        onClick={() => handlePageChange(i + 1)}
                      >
                        {i + 1}
                      </a>
                    </li>
                  ))}
                  <li>
                    <a
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      »
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="product-per-page d-flex align-items-center mt-3 mt-md-0">
                <label htmlFor="show-per-page">Show Per Page</label>
                <select name="sort" id="show-per-page" onChange={handleItemsPerPageChange}>
                  <option value={9}>9</option>
                  <option value={15}>15</option>
                  <option value={21}>21</option>
                  <option value={27}>27</option>
                </select>
              </div>
              </div>
            : <></> }
          </div>
        </div>
        {/* Shop Page Content End */}
      </div>
    </div>
  </div>
</div>

<QuickView prd={prdt} />
    </Layout>
    </>
  )
}

export default Celebration
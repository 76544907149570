import React from 'react'

const MyInput2 = ({
    inputType,
    type,
    name,
    title,
    placeholder,
    value,
    req,
    onChnge,
    errName,
    errMsg,
    ...props
}) => {
    const Input = inputType;
  return (
    <>
    <div className="single-input-item">
    <label htmlFor={name} className={req ? "required" : ''}>{title}</label>
     <Input type={type} name={name} placeholder={placeholder} value={value} onChange={onChnge} {...props} /> 
     {errName===name && errMsg!=='' ? <p style={{color:'#D82E2E'}}>{errMsg}</p> : '' }
     </div>   
    </>
  )
}

export default MyInput2

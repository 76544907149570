import React, { useState, useEffect } from 'react'
import Layout from '../partials/Layout'
import MyInput from './my_component/MyInput'
import MyButton from './my_component/MyButton' 
import { loginValidate, registerValidate } from './validations/validate';
import {useSelector, useDispatch} from 'react-redux'
import {login, register} from '../../actions/Useraction';
import Otpverification from './Otpverification';
import ForgotPassword from './ForgotPassword';

const loginInitial = {
  email:'',
  password:''
}
const registerInitial = {
  rname:'',
  remail:'',
  rpassword:'',
  rrepassword:''
};


function Login() {
  const [lg, setLg] = useState(loginInitial);
  const [rg, setRg] = useState(registerInitial);
  const [err, setErr] = useState(true);
  const [errName, setErrName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loginMsg, setLoginMsg] = useState("")
  const [registerMsg, setRegisterMsg] = useState("")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [otpVerify, setOTPVerify] = useState(false);
  const [forgotDiv, setForgotDiv] = useState(false);
  const state = useSelector((state)=>{
    return state.usr;
  })
  
  useEffect(()=>{
    //console.log("document.referrer", document.referrer, window.location.hostname );
    if(localStorage.getItem('isLoggedIn') === 'true' || false){
      window.location.href = '/';
    }
    }, [state.loading])

  const regiseterSubmit = (e)=>{
    e.preventDefault();
    console.log(rg)
    const check = registerValidate(rg);
    if(check.validate.valid)
    {
      setLoading(true)
      console.log("its ok", rg)
      let registerData = {
        pth: 'user-signup',
        data: rg
    };
    localStorage.setItem('otp_email', rg.remail); 
  
      dispatch(register(registerData))
      .then((data)=>{  
        setLoading(false)
        setErr(data.payload.error)
         setRegisterMsg(data.payload.msg)
        console.log("got ",data.payload)
        setTimeout(() => {
          setRegisterMsg('');
          if(data.payload.error===false)
          {
            setOTPVerify(true);  
          }
        }, 3000);
      
      
    })
      .catch((error)=>{
        console.log(error)
      })
    }
    else{
      setErrName(check.validate.nm);
     setErrMsg(check.validate.msg);
    }
  }

  const loginSubmit = (e)=>{
    e.preventDefault();
    console.log(lg)
    let check = loginValidate(lg);
    if(check.validate.valid)
    {
      setLoading(true)
      console.log("its ok", lg)
      let loginData = {
        pth: 'user-login',
        data: lg
    };
  
      dispatch(login(loginData))
      .then((data)=>{
        console.log("login", data)
        setLoading(false)
        setErr(data?.payload?.error)
        setLoginMsg(data?.payload?.msg)
        console.log("got ",data.payload)
        setTimeout(() => {
          setLoginMsg('');
        }, 3000);
      
      })
      .catch((error)=>{
        console.log(error)
      })
    } 
    else{
      setErrName(check.validate.nm);
     setErrMsg(check.validate.msg);
    }
  }

  const onLoginChange = (e)=>{
    setErrName('');
    let {name, value} = e.target;
    setLg({...lg, [name]:value})
  }   

  const onRegisterChange = (e)=>{
    setErrName('');
    let {name, value} = e.target;
    setRg({...rg, [name]:value})
  }

  return (
    <Layout>
<div>

  <div id="page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="page-title-content">
            <h1>Member Area</h1>
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li><a href="/login-signup" className="active breadcrumb_active">Login &amp; Register</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page-content-wrapper" className="p-9">
    <div className="container">
      <div className="row">
        <div className="col-lg-7 m-auto">
        
            <div className="login-register-wrapper">
            {otpVerify ? (
            <Otpverification emal={rg.remail} />
            )  
            :
                forgotDiv ? ( <ForgotPassword fshow={forgotDiv} /> ) :
             ( 
              <div> 
              <nav className="nav login-reg-tab-menu">
                <a className="active" id="login-tab" data-toggle="tab" href="#login">Login</a>
                <a id="register-tab" data-toggle="tab" href="#register">Register</a>
              </nav>
          
              <div className="tab-content" id="login-reg-tabcontent">
                <div className="tab-pane fade show active" id="login" role="tabpanel">
                  <div className="login-reg-form-wrap">
                    <form onSubmit={loginSubmit}>
                    <MyInput inputType="input" type="text" name="email" placeholder="Email *" value={lg.email} onChnge={onLoginChange} errName={errName} errMsg={errMsg} />
                    
                    <MyInput inputType="input" type="password" name="password" placeholder="Password *" value={lg.password} onChnge={onLoginChange} errName={errName} errMsg={errMsg} />
                      <div className="single-input-item">
                        <div className="login-reg-form-meta d-flex align-items-center justify-content-between">
                          <a href="javascript:void(0)" onClick={()=>setForgotDiv(true)} className="forget-pwd ml-auto">Forget Password?</a>
                        </div>
                      </div>
                      <div className="single-input-item">
                      <MyButton type="submit" name="login" clas="btn-login" value="Login" loading={loading} err={err} errMsg={loginMsg} />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="tab-pane fade" id="register" role="tabpanel">
                  <div className="login-reg-form-wrap">
                    <form onSubmit={regiseterSubmit}>
                    <MyInput inputType="input" type="text" name="rname" placeholder="Name *" value={rg.rname} onChnge={onRegisterChange} errName={errName} errMsg={errMsg} />

                    <MyInput inputType="input" type="text" name="remail" placeholder="Email *" value={rg.remail} onChnge={onRegisterChange} errName={errName} errMsg={errMsg} />
                      
                    <div className="row">
                        <div className="col-lg-6">
                        <MyInput inputType="input" type="password" name="rpassword" placeholder="Password *" value={rg.rpassword} onChnge={onRegisterChange} errName={errName} errMsg={errMsg} />
                        </div>
                        <div className="col-lg-6">
                        <MyInput inputType="input" type="password" name="rrepassword" placeholder="Repeat your password *" value={rg.rrepassword} onChnge={onRegisterChange} errName={errName} errMsg={errMsg} />
                        
                        </div>
                      </div>
                    
                      <div className="single-input-item">
                      <MyButton type="submit" name="register" cls="btn-login" value="Register" loading={loading} err={err} errMsg={registerMsg} />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              </div>
             )
                }   
            </div>
          
        </div>
      </div>
    </div>
  </div>

</div>

    </Layout>
  )
}

export default Login
import React, { useEffect, useState } from 'react'
import MyInput from './my_component/MyInput'
import MyButton from './my_component/MyButton' 
import { forgotPasswordValidate } from './validations/validate'
import {useSelector, useDispatch} from 'react-redux'
import { userNormalRoute } from '../../actions/Useraction'
import Otpverification from './Otpverification';

const initialValue = {
    femail:''
  }

const ForgotPassword = ({fshow}) => {
    const [lg, setLg] = useState(initialValue);  
    const [errName, setErrName] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [err, setErr] = useState(true);
    const [loginMsg, setLoginMsg] = useState("")
    const [loading, setLoading] = useState(false);
    const [forgotDiv, setForgotDiv] = useState(fshow);
    const state = useSelector((state)=>{
      return state.usr;
    })
    const dispatch = useDispatch();
    
    useEffect(()=>{
     //console.log("state ", state.loading)
      
      }, [state.loading])

    const onSubmitForgot = (e)=>{
        e.preventDefault();
        
        console.log("form ",lg)

        let check = forgotPasswordValidate(lg);
        if(check.validate.valid)
        {
          setLoading(true)
          console.log("its ok", lg)
          let loginData = {
            'pth': 'forgot-password',
            'data': lg,
            'method':'post'
        };
      
          dispatch(userNormalRoute(loginData))
          .then((data)=>{
            setLoading(false)
            setErr(data.payload.error)
            setLoginMsg(data.payload.msg)
            console.log("got ",data.payload)
            setTimeout(() => {
              setLoginMsg('');
              // if(data.payload.error===false)
              // {
              //   window.location.href = "/login-signup";
              // }
              
            }, 5000);
          
          })
          .catch((error)=>{
            console.log(error)
          })
        }
        else{
          setErrName(check.validate.nm);
         setErrMsg(check.validate.msg);
        }
      }

      const onChangeVal = (e)=>{
        setErrName('');
        let {name, value} = e.target;
        setLg({...lg, [name]:value})
      }

    return (
    <div>
       {forgotDiv ? 
        <div className="login-register-wrapper">
    
    <nav className="nav login-reg-tab-menu">
      <a className="active" id="login-tab" data-toggle="tab" href="#otp">Forgot Password</a>
     
    </nav>

    <div className="tab-content" id="login-reg-tabcontent">
      <div className="tab-pane fade show active" id="otp" role="tabpanel">
        <div className="login-reg-form-wrap">
          <form onSubmit={onSubmitForgot}>
          <MyInput inputType="input" type="text" name="femail" placeholder="Eneter your registered email-id *" value={lg.femail} onChnge={onChangeVal} errName={errName} errMsg={errMsg} />
          
          {/* <div className="single-input-item">
                        <div className="login-reg-form-meta d-flex align-items-center justify-content-between">
                          <a href="javascript:void(0)" onClick={()=>setForgotDiv(false)} className="forget-pwd ml-auto">Want to login?</a>
                        </div>
                      </div> */}

            <div className="single-input-item">
            <MyButton type="submit" name="submit" clas="btn-login" value="Submit" loading={loading} err={err} errMsg={loginMsg} />
            
            </div>
          </form>
        </div>
      </div>
      
    </div>
  </div>
  : ''}
    </div>
  )
}

export default ForgotPassword
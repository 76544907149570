import React from 'react'


function OptionalHeader() {
  return (
    <>

   

     <header id="header-area" className="header__3">
    <div className="ruby-container" style={{padding: '0 4%'}}>
      <div className="row">
        {/* Logo Area Start */} 
        <div className="col-3 col-lg-1 col-xl-2 m-auto">
          <a href="/" className="logo-area">
            <img src="/assets/img/vivan_logo_1.png" alt="Logo" className="img-fluid" style={{height:'auto', width:'110px'}} />
          </a>
        </div>
        {/* Logo Area End */}
        {/* Navigation Area Start */}     
        <div className="col-3 col-lg-9 col-xl-8 m-auto">
          <div className="main-menu-wrap">
            <nav id="mainmenu">
              <ul>
          
                <li><a href="/" className='active'>Home</a></li>
                <li><a href="/shop">Shop</a></li> 
                <li><a href="contact.html">New Arrivals</a></li>
                <li><a href="contact.html">About</a></li>
                <li><a href="/contact">Contact Us</a></li>
                <li><a href="/login-signup">Login/Signup</a></li>
              </ul>
            </nav>
          </div>
        </div>
        {/* Navigation Area End */}
        {/* Header Right Meta Start */}
        <div className="col-6 col-lg-2 m-auto">
          <div className="header-right-meta text-right">
            <ul>
              {/* <li><a href="#" className="modal-active"><i className="fa fa-search" /></a></li>
            
              <li className="shop-cart"><a href="#"><i className="fa fa-shopping-bag" /> <span className="count">3</span></a>
                <div className="mini-cart">
                  <div className="mini-cart-body">
                    <div className="single-cart-item d-flex">
                      <figure className="product-thumb">  
                        <a href="#"><img className="img-fluid" src="/assets/img/product-1.jpg" alt="Products" /></a>
                      </figure>
                      <div className="product-details">
                        <h2><a href="#">Sprite Yoga Companion</a></h2>
                        <div className="cal d-flex align-items-center">
                          <span className="quantity">3</span>
                          <span className="multiplication">X</span>
                          <span className="price">$77.00</span>
                        </div>
                      </div>
                      <a href="#" className="remove-icon"><i className="fa fa-trash-o" /></a>
                    </div>
                    <div className="single-cart-item d-flex">
                      <figure className="product-thumb">
                        <a href="#"><img className="img-fluid" src="/assets/img/product-2.jpg" alt="Products" /></a>
                      </figure>
                      <div className="product-details">
                        <h2><a href="#">Yoga Companion Kit</a></h2>
                        <div className="cal d-flex align-items-center">
                          <span className="quantity">2</span>
                          <span className="multiplication">X</span>
                          <span className="price">$6.00</span>
                        </div>
                      </div>
                      <a href="#" className="remove-icon"><i className="fa fa-trash-o" /></a>
                    </div>
                    <div className="single-cart-item d-flex">
                      <figure className="product-thumb">
                        <a href="#"><img className="img-fluid" src="/assets/img/product-3.jpg" alt="Products" /></a>
                      </figure>
                      <div className="product-details">
                        <h2><a href="#">Sprite Yoga Companion Kit</a></h2>
                        <div className="cal d-flex align-items-center">
                          <span className="quantity">1</span>
                          <span className="multiplication">X</span>
                          <span className="price">$116.00</span>
                        </div>
                      </div>
                      <a href="#" className="remove-icon"><i className="fa fa-trash-o" /></a>
                    </div>
                  </div>
                  <div className="mini-cart-footer">
                    <a href="/checkout" className="btn-add-to-cart">Checkout</a>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
        {/* Header Right Meta End */}
      </div>
    </div>
  </header>
 
    </>
  )
}

export default OptionalHeader
import React from 'react'

const MyInput = ({
    inputType,
    type,
    name,
    placeholder,
    value,
    onChnge,
    errName,
    errMsg,
    ...props            
}) => {
    const Input = inputType;
  return (
    <>
    <div className="single-input-item">
     <Input type={type} name={name} placeholder={placeholder} value={value} onChange={onChnge} {...props} /> 
     {errName===name && errMsg!=='' ? <p style={{color:'#D82E2E'}}>{errMsg}</p> : '' }
     </div>   
    </>
  )
}

export default MyInput

import React, { useEffect, useState } from 'react'
import MyInput from './my_component/MyInput'
import MyButton from './my_component/MyButton' 
import { changePasswordValidate } from './validations/validate'
import {useSelector, useDispatch} from 'react-redux'
import { userNormalRoute } from '../../actions/Useraction'
import Otpverification from './Otpverification';
import OptionalLayout from '../partials/OptionalLayout'
import { useParams } from 'react-router-dom'

const initialValue = {
    password:'',
    re_password:''
  }

const ChangePassword = () => {
    const [lg, setLg] = useState(initialValue);  
    const [errName, setErrName] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [err, setErr] = useState(true);
    const [loginMsg, setLoginMsg] = useState("")
    const [loading, setLoading] = useState(false);
    const {id,str} = useParams();
    const state = useSelector((state)=>{
      return state.usr;
    })
    const dispatch = useDispatch();
    
    // useEffect(()=>{
    //  //console.log("state ", state.loading)
    //  console.log(str);
      
    //   }, [state.loading])

    const onSubmitPassword = (e)=>{
        e.preventDefault();
        
        console.log("form ",lg)

        let check = changePasswordValidate(lg);
        if(check.validate.valid)
        {
          setLoading(true)
          console.log("its ok", lg)
          let fmData = {
            'pth': 'change-password/'+id+'/'+str,
            'data': lg,
            'method':'post'
        };
      
          dispatch(userNormalRoute(fmData))
          .then((data)=>{
            setLoading(false);
            setErr(data.payload.error)
            setLoginMsg(data.payload.msg)
            console.log("got ",data.payload)
            setTimeout(() => {
              setLoginMsg('');
              if(data.payload.error===false)
              {
                window.location.href = "/"; 
              }
              
            }, 3000);
          
          })
          .catch((error)=>{
            console.log(error)
          })
        }
        else{
          setErrName(check.validate.nm);
         setErrMsg(check.validate.msg);
        }
      }

      const onChangeVal = (e)=>{
        setErrName('');
        let {name, value} = e.target;
        setLg({...lg, [name]:value})
      }

    return (
        <>
        <OptionalLayout>
        <div>
        
          
        
          <div id="page-content-wrapper" className="p-9">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 m-auto">
                
                    <div className="login-register-wrapper">
                    
                    
                      <nav className="nav login-reg-tab-menu">
      <a className="active" id="login-tab" data-toggle="tab" href="#otp">Change Password</a>
     
    </nav>
                  
                      <div className="tab-content" id="login-reg-tabcontent">
                        <div className="tab-pane fade show active" id="login" role="tabpanel">
                          <div className="login-reg-form-wrap">
                          <form onSubmit={onSubmitPassword}>
          <MyInput inputType="input" type="password" name="password" placeholder="Eneter new password *" value={lg.password} onChnge={onChangeVal} errName={errName} errMsg={errMsg} />
          <MyInput inputType="input" type="password" name="re_password" placeholder="Re-Eneter password *" value={lg.re_password} onChnge={onChangeVal} errName={errName} errMsg={errMsg} />
          
            <div className="single-input-item">
            <MyButton type="submit" name="submit" clas="btn-login" value="Submit" loading={loading} err={err} errMsg={loginMsg} />
            
            </div>
          </form>
                          </div>
                        </div>
                        
                      </div>
                      
                     
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        
        </div>
        
            </OptionalLayout>
            </>
  )
}

export default ChangePassword
import React, { useState } from 'react'
import MyInput from './my_component/MyInput'
import MyButton from './my_component/MyButton' 
import { otpValidate } from './validations/validate'
import {useSelector, useDispatch} from 'react-redux'
import { userOTP } from '../../actions/Useraction'
import { postpData } from './apis/service'
import { Link } from 'react-router-dom'


const Otpverification = ({emal}) => {
  const otpInitial = {
    otp:'',
    email:localStorage.getItem('otp_email') || emal
  }  


    const [lg, setLg] = useState(otpInitial);  
    const [errName, setErrName] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [err, setErr] = useState(true);
    const [loginMsg, setLoginMsg] = useState("")
    const [loading, setLoading] = useState(false);
    const state = useSelector((state)=>{
      return state.usr;
    })
    const dispatch = useDispatch();
    
   
    const otpSubmit = (e)=>{
        e.preventDefault();
        
        console.log(lg)

        let check = otpValidate(lg);
        if(check.validate.valid)
        {
          setLoading(true)
          console.log("its ok", lg)
          let loginData = {
            pth: 'verify-otp',
            data: lg
        };
      
          dispatch(userOTP(loginData))
          .then((data)=>{
            setLoading(false)
            setErr(data.payload.error)
            setLoginMsg(data.payload.msg)
            //console.log("got ",data.payload)
            setTimeout(() => {
              setLoginMsg('');
              if(data.payload.error===false)
              {
                setLg.otp='';
                window.location.href="/login-signup";
                localStorage.removeItem('otp_email');
              }
            }, 3000);
          
          })
          .catch((error)=>{
            console.log(error)
          })
        }
        else{
          setErrName(check.validate.nm);
         setErrMsg(check.validate.msg);
        }
      }

      const onOtpChange = (e)=>{
        setErrName('');
        let {name, value} = e.target;
        setLg({...lg, [name]:value})
      }

      const resendOtp = async ()=>{
        try{
          const pth = "resend-otp";
          const email = localStorage.getItem("otp_email");
          if (!email) {
            window.location.href = '/login-signup';
            return;
          }
       
          const response = await postpData(pth,{email});
  
          setErr(response.data.error)
          setLoginMsg(response.data.msg)
          setTimeout(() => {
            setLoginMsg('');
          }, 3000);
          console.log("resend otp", response.data);
          
        }
        catch(error){
          console.log("error", error)
        }
      }



    return (
    <div>
        <div className="login-register-wrapper">
    
    <nav className="nav login-reg-tab-menu">
      <a className="active" id="login-tab" data-toggle="tab" href="#otp">Verify OTP</a>
     
    </nav>

    <div className="tab-content" id="login-reg-tabcontent">
      <div className="tab-pane fade show active" id="otp" role="tabpanel">
        <div className="login-reg-form-wrap">
          <form onSubmit={otpSubmit}>
          <MyInput inputType="input" type="text" name="otp" placeholder="OTP *" value={lg.otp} onChnge={onOtpChange} errName={errName} errMsg={errMsg} />
          <div className="single-input-item">
                      <div className="login-reg-form-meta d-flex align-items-center justify-content-between">
                        
                        <Link className="forget-pwd ml-auto" onClick={resendOtp} >Resend OTP ?</Link>
                      </div>
                    </div>
            <div className="single-input-item">
            {/* <MyButton type="submit" name="otp" clas="btn-login" value="Submit" loading={loading} err={err} errMsg={loginMsg} /> */}
            <button type="submit" name="otp" clas="btn-login">{loading ? 'Please Wait...' : 'Submit'} </button>
            {loginMsg !== '' ?  <p style={{textAlign:'center', color:err ? '#D82E2E' : 'green' }} > <br />{loginMsg}</p> : '' }

            </div>
          </form>
        </div>
      </div>
      
    </div>
  </div>
    </div>
  )
}

export default Otpverification
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Banner() {
  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <div className="ruby-container" style={{ padding: 0 }}>
        <div className="row">
          <div className="col-lg-12">
            <Carousel
              swipeable={true}
              showStatus={false}
              autoPlay={true}
              interval={3000}
              stopOnHover={false}
              infiniteLoop={true}
              showThumbs={false}
            >
              <div style={{ position: 'relative' }}>
                <img 
                  src="/assets/img/slider/slider1.jpg" 
                  alt="image" 
                  style={{ width: '100%', height: 'auto' }} 
                />
       
                <div
                  onClick={() => handleClick('https://www.vivanhomes.com/shop')}
                  style={{
                    position: 'absolute',
                    top: '440px',
                    left: '230px',
                    width: '313px', 
                    height: '59px',
                    zIndex: 10,
                    backgroundColor: 'rgba(0,0,0,0)',
                    cursor: 'pointer', 
                  }}
                />
              </div>

              <div style={{ position: 'relative' }}>
                <img 
                  src="/assets/img/slider/slider2.jpg" 
                  alt="image" 
                  style={{ width: '100%', height: 'auto' }} 
                />
              
                <div
                  onClick={() => handleClick('https://www.vivanhomes.com/shop')}
                  style={{
                    position: 'absolute',
                    top: '440px',
                    left: '950px',
                    width: '310px',
                    height: '64px',
                    zIndex: 10,
                    backgroundColor: 'rgba(0,0,0,0)',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
